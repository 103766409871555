export const fetchAllItems = () => ({ items: state }) => state.items;
export const isFetching = ({ items: state }) => state.itemsFetching;
export const getTotal = () => ({ items: state }) => state.total;
export const getItemsCount = () => ({ items: state }) => state.items.length;
export const getIsCart = () => ({ items: state }) => state.isCart;
export const getIsOpen = () => ({ items: state }) => state.isOpen;
export const getCuotas = () => ({ items: state }) => state.datos.datosPedido.Cuotas;
export const getCuotasHeader = () => ({ items: state }) => {
    if (state.datos.datosPedido.Cuotas == null){
        return 1;
    }else{
        return state.datos.datosPedido.Cuotas;
    }
} 
export const getCurrentLista = () => ({ items: state }) => state.datos.datosPedido.currentLista || null;
export const isLoading = () => ({ items : state}) => state.loading.isLoading;
export const getNroPedido = () => ({ items : state}) => state.pedido.nroPedido;
export const getDatosCliente = () => ({ items : state}) => state.datos.cliente;
export const getDatosPedido = () => ({ items: state }) => state.datos.datosPedido;
export const getTipoFactura = () => ({ items: state }) => state.datos.datosPedido.Tipo_Factura || 'B';
export const getTiposFactura = () => ({ items: state }) => state.datos.tiposFactura;
export const getTipoEnvio = () => ({ items: state }) => state.datos.datosPedido.Retira_Local;
export const getNroFactura = () => ({ items: state }) => state.datos.nroFactura;
export const getPedidoFacturado = () => ({ items: state }) => state.datos.facturado;
export const getCodOperador = () => ({ items: state }) => state.datos.datosPedido.OperadorCod;
export const getMedio = () => ({ items: state }) => state.datos.datosPedido.codMedio;
export const getNewPedido = () => ({ items: state }) => {
    
    if (state.datos.datosPedido.draft == false) {
        console.log(state.datos.datosPedido,'<<')
        return false
    } else {
        return true;
    }
} 

export const getAfacturar = () => ({ items: state }) => {

    if (state.datos.datosPedido.idEstado == '7') {
        return true;
    } else {
        return false;
    }
} 

export const getCurrentFormaPago = () => ({ items: state }) => {
    if (state.datos.datosPedido.draft == false) {
        return [state.datos.datosPedido.idFormaPago];
    } else {
        return state.datos.datosPedido.currentFormaPago || null;
    }
   
}   
export const getPayments = () => ({ items: state }) => state.datos.datosPedido.payments || [];
export const getImporte = (num) => ({ items: state }) => state.datos.datosPedido["Importe"+num];

export const getCondicionesIva = () => ({ items: state }) => state.datos.condicionesIva || [];
export const getCondicionIva = () => ({ items: state }) => state.datos.datosPedido.condicionIva || 3;

export const getStoreAddress = () => ({ items: state }) => state.datos.datosPedido.storeAddress || '';
export const getFullStoreAddress = () => ({ items: state }) => state.datos.datosPedido.storeInfo || {};

export const getListaCuotas = () => ({ items: state }) => {
    if (state.datos.datosPedido.draft == false){
        return [state.datos.datosPedido.Cuotas];
    }else{
       return  state.datos.datosPedido.currentListaCuotas || [1];
    }
    
}  
export const isDraft = () => ({ items: state }) => state.datos.datosPedido.draft;
