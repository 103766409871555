import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {Modal, Box, MenuItem, Select } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Api from '../../../state/Api';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import TextField  from '@mui/material/TextField';
import * as itemsActions from '../../../state/orders/actions'
import { useDispatch } from 'react-redux';

const textfieldStyle = {
    marginBottom: '10px'
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const ModalDireccion = (props) => {

    const [Ape_Entrega, setApeEntrega] = React.useState();
    const [Nom_Entrega, setNomEntrega] = React.useState();
    const [Calle_Entrega, setCalleEntrega] = React.useState();
    const [Nro_Entrega, setNroEntrega] = React.useState();
    const [Edif, setEdif] = React.useState();
    const [CP_Entrega, setCPentrega] = React.useState();
    const [Loca_Entrega, setLocaEntrega] = React.useState();
    const [Parti_Entrega, setPartiEntrega] = React.useState();
    const [Provi_Entrega, setProviEntrega] = React.useState();
    const [listadoProvincias, setListadoProvincias] = useState([]) 
    const [listadoPartidos, setListadoPartidos] = useState([])
    const [listadoLocalidades, setListadoLocalidades] = useState([])
    const [openModalEditar, setOpenModalEditar] = React.useState(false);
    const dispatch = useDispatch();
    const datosCliente = props.datosCliente;


    const checkLocalidades = () => {
        if (Provi_Entrega == 'C' && !!CP_Entrega && !!Loca_Entrega && !!Provi_Entrega) {
            return true;
        }

        if (Provi_Entrega !== 'C' && !!CP_Entrega && !!Loca_Entrega && !!Parti_Entrega && !!Provi_Entrega) {
            return true;
        }
        return false;

    }

    const checkFields = () => {
        if (!!Calle_Entrega && !!Nro_Entrega && !!Edif && checkLocalidades()) {
            return true;
        } else {
            return false;
        }
    }

    const cambioProvincia = async (proviChange) => {
        if (proviChange == 'C') {
            setListadoLocalidades([{ Codigo: 'CAP', Descripcion: 'Capital Federal' }])
            setLocaEntrega('CAP')
        } else {
            let dataPart = await Api.req.get(`data/provincia/${proviChange}/partidos`);
            setListadoPartidos(dataPart.data.data)
        }
    }

    const cambioPartido = async (partiChange) => {
        console.log(partiChange)
        let dataPart = await Api.req.get(`data/partido/${partiChange}/localidades`);
        setListadoLocalidades(dataPart.data.data)
    }

    const getProvinciasData = async () => {
        let dataProv = await Api.req.get(`data/provincias`);
        setListadoProvincias(dataProv.data.data)
    }

    const getLocalidadesData = async () => {
        let dataProv = await Api.req.get(`data/provincias`);
        setListadoProvincias(dataProv.data.data)

        let dataPart = await Api.req.get(`data/provincia/${datosCliente.idProvincia}/partidos`);
        setListadoPartidos(dataPart.data.data)

        if (datosCliente.idProvincia == 'C') {
            setListadoLocalidades([{ Codigo: 'CAP', Descripcion: 'Capital Federal' }])
            setLocaEntrega('CAP')
        } else {
            let dataLoc = await Api.req.get(`data/provincia/${datosCliente.idPartido}/localidades`);
            setListadoLocalidades(dataLoc.data.data)
        }
    }

    const handleCloseModalAgree = () => {
        props.onCloseModalAgree()
    }

    const handleCopyData = () => {
        setNomEntrega(datosCliente.Ape_Cliente)
        setApeEntrega(datosCliente.Nom_Cliente)
        setCalleEntrega(datosCliente.Calle)
        setNroEntrega(datosCliente.Nro)
        setEdif(datosCliente.Edif)
        setCPentrega(datosCliente.CP)
        setLocaEntrega(datosCliente.Localidad)
        setPartiEntrega(datosCliente.idPartido)
        setProviEntrega(datosCliente.idProvincia)
        props.onCloseModalAgree()
        setOpenModalEditar(true)
        getLocalidadesData()
    }

    const handleCloseModal = () => {
        setOpenModalEditar(false)
    }

    const handleBlankAddress = () => {
        setNomEntrega('');
        setApeEntrega('');
        setCalleEntrega('')
        setNroEntrega('')
        setEdif('')
        setCPentrega('')
        setLocaEntrega('')
        setPartiEntrega('')
        setProviEntrega('')
        setOpenModalEditar(true);
        props.onCloseModalAgree()
        getProvinciasData()
    }

    const guardarData = () => {
        let partiDesc;
        let proviDesc = listadoProvincias.find((provincia) => provincia.Codigo == Provi_Entrega ).Descripcion;
        if(Provi_Entrega != 'C'){
            partiDesc = listadoPartidos.find((partido) => partido.Codigo == Parti_Entrega).DESCRIPCION;
        }else{
            partiDesc = '';
        }
        
        let addressData = {
            Nom_Entrega : Nom_Entrega,
            Ape_Entrega : Ape_Entrega,
            Calle_Entrega: Calle_Entrega,
            Nro_Entrega: Nro_Entrega,
            Edif_Entrega: Edif,
            CP_Entrega: CP_Entrega,
            Loca_Entrega: Loca_Entrega,
            Parti_Entrega: partiDesc,
            Provi_Entrega: proviDesc,
            idProvi_Entrega: Provi_Entrega,
            idParti_Entrega: Parti_Entrega,
        }


        dispatch(itemsActions.setAddressData(addressData))
        handleCloseModal()
    }


return(
    <>
        <Dialog
              open={props.openModalAgree}
              onClose={handleCloseModalAgree}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
          >
              <DialogTitle id="alert-dialog-title">
                  {"Editar Domicilio"}
              </DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                        ¿ Es el mismo domicilio del cliente ?
                  </DialogContentText>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => {handleBlankAddress()} }>No</Button>
                  <Button onClick={handleCopyData} autoFocus>
                      SI
                  </Button>
              </DialogActions>
          </Dialog>  
          <Modal
              open={openModalEditar}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
          >
              <Box sx={style}>
                  <div>
                    <TextField
                        label="Nombre"
                        variant="outlined"
                        value={Nom_Entrega}
                        onChange={(e) => setCalleEntrega(e.target.value)}
                        size="small"
                        style={textfieldStyle}
                        fullWidth
                    />
                  </div>
                  <div>
                        <TextField
                            label="Apellido"
                            variant="outlined"
                            value={Ape_Entrega}
                            onChange={(e) => setCalleEntrega(e.target.value)}
                            size="small"
                            style={textfieldStyle}
                            fullWidth
                        />
                  </div>
                  <div>
                    <TextField
                        label="Calle"
                        variant="outlined"
                        value={Calle_Entrega}
                        onChange={(e) => setCalleEntrega(e.target.value)}
                        size="small"
                        style={textfieldStyle}
                        fullWidth
                    />
                  </div>
                  <div>
                    <TextField
                        label="Nro"
                        variant="outlined"
                        value={Nro_Entrega}
                        onChange={(e) => setNroEntrega(e.target.value)}
                        size="small"
                        style={textfieldStyle}
                        fullWidth
                    />
                  </div>
                  <div>
                      <TextField
                          label="Edif"
                          variant="outlined"
                          value={Edif}
                          onChange={(e) => setEdif(e.target.value)}
                          size="small"
                          style={textfieldStyle}
                          fullWidth
                      />

                  </div>
                  <div>
                      <TextField
                          label="CP"
                          variant="outlined"
                          value={CP_Entrega}
                          onChange={(e) => setCPentrega(e.target.value)}
                          size="small"
                          style={textfieldStyle}
                          fullWidth
                      />
                  </div>
                  <div>
                    <FormControl fullWidth={true} style={textfieldStyle} sx={{ minWidth: 120 }}>
                            <InputLabel id="partido-label">Provincia</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth={true}
                                value={Provi_Entrega}
                                label="Provincia"
                                onChange={(e) => { setProviEntrega(e.target.value); cambioProvincia(e.target.value) } }

                            >
                                {listadoProvincias ? listadoProvincias.map(
                                    (item, index) => (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                                ) : ''}
                                <InputLabel id="test-select-label">Label</InputLabel>
                            </Select>
                        </FormControl>
                  </div>
                  <div>
                    <FormControl fullWidth={true} style={textfieldStyle} sx={{ minWidth: 120 }}>
                            <InputLabel id="partido-label">Partido</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                fullWidth={true}
                                value={Parti_Entrega}
                                label="Tipo Docu"
                                onChange={(e) => { setPartiEntrega(e.target.value); cambioPartido(e.target.value)  } }

                            >
                                {listadoPartidos ? listadoPartidos.map(
                                    (item, index) => (<MenuItem key={index} value={item.Codigo}>{item.DESCRIPCION}</MenuItem>)
                                ) : ''}
                            </Select>
                        </FormControl>

                  </div>
                  <div>
                    <FormControl style={textfieldStyle} fullWidth={true} sx={{ minWidth: 120 }}>
                        <InputLabel id="partido-label">Localidad</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={Loca_Entrega}
                            label="Tipo Docu"
                            onChange={(e) => setLocaEntrega(e.target.value)}

                        >
                            {listadoLocalidades ? listadoLocalidades.map(
                                (item, index) => (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                            ) : ''}
                        </Select>
                    </FormControl>
                  </div>
                  
   
                 <div>
                    <Button disabled={!checkFields()} onClick={guardarData} variant="contained">Guardar</Button>
                    <Button onClick={ () => setOpenModalEditar(false) } style={{ float : 'right' , display : 'none' }} color="secondary" variant="contained">Cancelar</Button>
                 </div>
              </Box>
          </Modal>
    </>)



}

 export default ModalDireccion;