import React, { useState } from 'react';
import { Layout, Menu, Card, Switch } from 'antd';
import { Grid, Button } from '@mui/material';
import Busqueda  from './Busqueda';
import Resultados from './Resultados';
import AddIcon from '@mui/icons-material/Add';

const { Header, Content, Footer } = Layout;

const Cliente = () => {
    const [resultados, setResultados ] = useState([]);
    const [nuevo,setNuevo] = useState(false)
    const [buscando, setBuscando] = useState(false);

    const handleBusquedaInit = () => {
        setBuscando(true)
    }

    const handleBusquedaResult = (result) => {
        setResultados(result)
        setBuscando(false)
    }

    const handleCrearCliente = () => {
        setNuevo(true)
    }

    const handleCerroModal = () => {
        setNuevo(false)
    }

  return (
    <>
        <div style={{height: 'calc(88vh)', overflow: 'scroll'}}>
              <Button style={{ backgroundColor: '#6fbe22', position: 'absolute',right: '20px',bottom: '10px', zIndex: 999}} onClick={handleCrearCliente} variant="contained"> <AddIcon/> NUEVO CLIENTE</Button>
            <Grid container spacing={1} s={12} direction="row" style={{ backgroundColor: '#f2f2f2', padding: 10 }}  >
                <Grid item xs={12} sm={12} lg={12}>
                      <Busqueda  onBusquedaResult={handleBusquedaResult} onBusquedaInit={handleBusquedaInit} />
                </Grid>            
                <Grid item xs={12} sm={12} lg={12}>
                    <Grid container direction="column" spacing={1} sx={{ flexGrow: 1 }} >
                        <Grid item xs={12} sm={12} lg={4}>
                            <Resultados buscando={buscando} onCerroModal={handleCerroModal} nuevo={nuevo} results={resultados} />
                        </Grid>
                    </Grid>
                </Grid>            
            </Grid>
        </div>
    </>
  );
}

export default Cliente;
