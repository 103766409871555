import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';

import NotificationsIcon from '@mui/icons-material/Notifications';
import LogoutIcon from '@mui/icons-material/Logout'
import { Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as authActions from '../../state/auth/actions'
import { useDispatch } from 'react-redux';
import jwt_decode from "jwt-decode";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const showUserStyle = {
  background: '#6b6b6b',
  padding: '10px',
  borderRadius: '10px',
  margin: '0 10px'
}


export default function AppBarApp(props) {
//   const theme = props.theme;
//   const classes = useStyles();
  var token = localStorage.getItem('jwt');
  var decoded = jwt_decode(token);
  const dispatch = useDispatch();
  const open = props.open;
  const handleDrawerClose = props.handleDrawerClose;
  const handleDrawerOpen = props.handleDrawerOpen;

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const navigate = useNavigate(); 
  
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
   
    dispatch(authActions.logout())
    //navigate('/login');
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  return (
    <div>
    <CssBaseline />
      <AppBar 
        position="fixed"
        open={open}
        style={{ backgroundColor: '#18202c' }}
      >
        <Toolbar style={{ backgroundColor: '#18202c', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
        
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 0 , width: '33%'}}>
          </Box>
          <Box sx={{ flexGrow: 1 , textAlign: 'center'}}>
            <img src='./assets/logo-tevecompras-title.png' style={{height: '40px', overflow: 'hidden' }} />
          </Box>          
          <Box sx={{ flexGrow: 0, width: '33%', flexDirection: 'row', textAlign: 'right' }}>
              <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="info">
                  <MailIcon />
                </Badge>
              </IconButton>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <span style={showUserStyle}> 
              {decoded.username.toUpperCase() }
              </span>
              <IconButton onClick={handleLogout} >
                <LogoutIcon style={{ color : 'white' }} />
              </IconButton>
          </Box>          
        </Toolbar>
      </AppBar>
    </div>
  );
}