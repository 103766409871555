import React, { useState, useEffect } from 'react';
// import { Layout, Card, TextField, Row, Col } from 'antd';
import { Typography, InputLabel, Chip, MenuItem, ToggleButtonGroup, ToggleButton, Select, Grid} from '@mui/material';
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';

import * as storeSelector from '../../../state/store/selectors';
import * as storeActions  from '../../../state/store/actions';
import * as itemsSelector from '../../../state/orders/selectors';
import * as itemsActions from '../../../state/orders/actions';
import Api from '../../../state/Api';
import jwt_decode from "jwt-decode";
import gridStyle from '../gridStyle';

const OrderHeader = () => {
  const dispatch = useDispatch();

  const nroPedido = useSelector(itemsSelector.getNroPedido())
  const medio = String(useSelector(itemsSelector.getMedio()))
  const [listadoMedios, setListadoMedios ] = React.useState([]);
  const isDraft = useSelector(itemsSelector.isDraft());
  const cuotas = useSelector(itemsSelector.getCuotasHeader());
  const [formaPago, setFormaPago] = React.useState(2);
  const codOperador = String(useSelector(itemsSelector.getCodOperador()))
  const handleChangeOperador = (event) => { dispatch(itemsActions.setOperador(event.target.value))  }
  const handleChangeMedio = (event) => { dispatch(itemsActions.setMedio(event.target.value)) };
  const handleChangeCuotas = (event) => { dispatch(itemsActions.updateCuotas(event.target.value, nroPedido)) };
  const handleChangeFormaPago = (event) => { setFormaPago(event.target.value); };

  const tipoFactura = useSelector(itemsSelector.getTipoFactura());
  const tiposFactura = useSelector(itemsSelector.getTiposFactura());
  const [operadores, setOperadores ] = React.useState([])

  const handleChangeTipoFactura = async (event, tipoFactura) => {
    if(tipoFactura){
      try {
        let result = await Api.req.put(`orders/order/${nroPedido}/tipoFactura`, { 'tipo': tipoFactura })
        dispatch(itemsActions.fetchAll(nroPedido))
        dispatch(itemsActions.setTipoFactura(tipoFactura))
        if (tipoFactura == 'A') { dispatch(itemsActions.setCondicionIva(1)) }
        if (tipoFactura == 'B') { dispatch(itemsActions.setCondicionIva(3)) }
      } catch (error) {
        console.log(error)
      }

    }
    
  };

  const handleChangeCondicionIva = (event) => {
    dispatch(itemsActions.setCondicionIva(event.target.value))
  };

  const allCuotas = useSelector(itemsSelector.getListaCuotas());
  const condicionIva = useSelector(itemsSelector.getCondicionIva());
  const allCondicionesIva = useSelector(itemsSelector.getCondicionesIva());
  const allFormasDePago = useSelector(storeSelector.fetchFormasDePago());
  const isNewPedido = useSelector(itemsSelector.getNewPedido());
  const isFacturado = useSelector(itemsSelector.getPedidoFacturado());
  const itemsPedido = useSelector(itemsSelector.fetchAllItems());
  const tieneItems = itemsPedido.length > 0 ? true : false;


  useEffect(() => {
    dispatch(storeActions.fetchFormasDePago());
    const fetchData = async () => {
      // get the data from the api
      var token = localStorage.getItem('jwt');
      var decoded = jwt_decode(token);
      if(isNewPedido){
        dispatch(itemsActions.setTipoFactura('B'))
        dispatch(itemsActions.setOperador(decoded.codO))
        dispatch(itemsActions.setMedio(30))
        dispatch(itemsActions.setCondicionIva(3))
      }
      
      const dataOperadores = await Api.req.get('/store/operadores');
      setOperadores(dataOperadores.data.data);

      const dataMedios = await Api.req.get('/store/medios');
      setListadoMedios(dataMedios.data.data);
    }

    fetchData()
      .catch(console.error);

  }, [])

  return (
      <Grid container spacing={0} s={12} style={gridStyle} >
              <Grid container spacing={2} s={12} direction="row" style={{}} >
                <Grid item xs={6} >
                  <Typography variant="h5" sx={{ fontFamily:'Work Sans', fontWeight: 600}}>Pedido Nro. {nroPedido}</Typography>
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right'}} >
                </Grid>          

                <Grid item xs={3} >
                <InputLabel shrink htmlFor="select-multiple-native">
                  Vendedor
                </InputLabel>                  
                  <Select
                    value={codOperador}
                    onChange={handleChangeOperador}
                    size="small"
                    fullWidth
                    variant="outlined"
                    readOnly={!isNewPedido}
                    disabled={isFacturado}
                    style={{backgroundColor: 'white'}}
                  >
                    {operadores.length > 0 && operadores.map(
                      (operador) => (
                        <MenuItem value={operador.nro_operador}>{operador.codigo}</MenuItem>
                      )
                    )}
                   
                  </Select>
                {/* </FormControl> */}
                </Grid>            
                <Grid item xs={3} >
                <InputLabel shrink htmlFor="select-multiple-native">
                    Medio
                  </InputLabel>                   
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={medio}
                    // label="Medio"
                    onChange={handleChangeMedio}
                    size="small"
                    fullWidth
                    variant="outlined"
                    readOnly={!isNewPedido}
                    disabled={isFacturado}
                    style={{backgroundColor: 'white'}}
                  >
                      {listadoMedios.length > 0 && listadoMedios.map(
                        (medio) => (
                          <MenuItem value={medio.Codigo}>{medio.Descripcion}</MenuItem>
                        )
                      )}
                  </Select>
                </Grid>
                <Grid item xs={1} >   
                  <InputLabel shrink htmlFor="select-multiple-native">
                    Cuotas
                  </InputLabel>         
        
                  <Select
                    value={String(cuotas)}
                    //defaultValue = "6"
                    onChange={handleChangeCuotas}
                    size="small"
                    readOnly={!isNewPedido || !tieneItems}
                    disabled={isFacturado}
                    fullWidth
                    variant="outlined"
                    // style={{backgroundColor: 'white'}}
                  >
                    {allCuotas && allCuotas.map((inst, index) => {
                      return (
                      <MenuItem key={index} value={inst}>{inst}</MenuItem>
                      )
                    })}
                  </Select>                      
                </Grid>                
                <Grid item xs={1.5} >         
                  <InputLabel shrink htmlFor="select-multiple-native" >
                    Tipo Factura
                  </InputLabel>                   
                    <ToggleButtonGroup
                        label="Tipo Factura"
                        value={tipoFactura}
                        disabled={!isNewPedido || isFacturado}
                        exclusive
                        onChange={handleChangeTipoFactura}
                        // size="small"
                        fullWidth
                        >
                        {tiposFactura && tiposFactura.map((elem) => {
                            return (
                              <ToggleButton value={elem.tipoFactura} disabled={!elem.habilitada} aria-label="Factura B" style={{ height: 40 }}>
                                <Typography variant="h5" sx={{ fontFamily: 'Work Sans', fontWeight: 600 }}>{elem.tipoFactura}</Typography>
                              </ToggleButton>
                            )
                          })
                        }
                    </ToggleButtonGroup>                          
                </Grid>                                 
                <Grid item xs={3} >
                  <InputLabel shrink htmlFor="select-multiple-native">
                    Condiciones Iva
                  </InputLabel>
                  <Select
                    value={condicionIva}
                    //defaultValue = "6"
                    onChange={handleChangeCondicionIva }
                    size="small"
                    readOnly={!isNewPedido}
                    disabled={isFacturado}
                    fullWidth
                    variant="outlined"
                    // style={{backgroundColor: 'white'}}
                  >
                    {allCondicionesIva && allCondicionesIva.map((condicion, index) => {
                      return (
                        <MenuItem key={index} value={condicion.Codigo}>{condicion.Descripcion}</MenuItem>
                      )
                    })}
                  </Select>   
                </Grid>
              </Grid>                                        
            </Grid>
  );
}

export default OrderHeader;