import Api from '../Api';

import { ADD, FETCH, FETCH_ITEMS_SUCCESS, FETCH_DOCUMENTOS_SUCCESS, FETCH_FORMASDEPAGO_SUCCESS, FETCH_MEDIOS_SUCCESS, 
    FETCH_OPERADORES_SUCCESS, FETCH_TARJETAS_SUCCESS,  FETCH_ITEMS_FAILURE ,TOASTS_ADD, TOASTS_REMOVE} from './constants';

let previousId = 0;

export const addToast = (toast,type) => dispatch => {
    let id = previousId ++;
    dispatch({ type: TOASTS_ADD, data: { id : id , toast : toast , type : type } })
} 

export const add = skus => (dispatch) => {
  // console.log(users);
  // if (typeof users === 'number') dispatch({ type: USER_QUEUE_ADD, id: skus });
  // else dispatch({ type: ADD, data: skus });
};

export const fetchFormasDePago = () => async (dispatch) => {
    dispatch({ type: FETCH });
    console.log('pasa x fetch items')
    try {
        const { data } = await Api.req.get(`/store/formasdepago`);
        const parsedData = data.data;

        if(parsedData){
            dispatch({ type: FETCH_FORMASDEPAGO_SUCCESS, payload: parsedData })
        }
    }
    catch (err) {
        console.log(err)
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_ITEMS_FAILURE, error: (error || err) });
    }

};