import produce from 'immer';
import { ADD, FETCH, FETCH_ITEMS_SUCCESS, FETCH_DOCUMENTOS_SUCCESS, FETCH_FORMASDEPAGO_SUCCESS, FETCH_MEDIOS_SUCCESS, 
    FETCH_OPERADORES_SUCCESS, FETCH_TARJETAS_SUCCESS,  FETCH_ITEMS_FAILURE , TOASTS_ADD, TOASTS_REMOVE } from './constants';

export const initialState = {
  loading: { isLoading : false  },
  settings: [],
  documentos: [],
  formasdepago: [],
  medios: [],
  operadores: [],
  tarjetas: [],
  itemsFetching: false,
  errors: false,
  toasts: {
    lastId: 0,
    items : []
  },
};

const reducer = (state = initialState, action) => { return produce(state, (draftState) => {
    switch(action.type) {

        case FETCH_FORMASDEPAGO_SUCCESS:
            draftState.formasdepago = action.payload;
            draftState.loading.isLoading = false;
            break;

        case FETCH_DOCUMENTOS_SUCCESS:
            draftState.documentos = action.payload;
            draftState.loading.isLoading = false;
            break;

        case FETCH_MEDIOS_SUCCESS:
            draftState.medios = action.payload;
            draftState.loading.isLoading = false;
            break;            

        case FETCH_OPERADORES_SUCCESS:
            draftState.operadores = action.payload;
            draftState.loading.isLoading = false;
            break;

        case FETCH_TARJETAS_SUCCESS:
            draftState.tarjetas = action.payload;
            draftState.loading.isLoading = false;
            break;


        case FETCH: 
            draftState.loading.isLoading = true;
            draftState.errors = false;
            break;

        case FETCH_ITEMS_FAILURE: 
            draftState.isFetching = false;
            draftState.errors = true;
            break;            

        case TOASTS_ADD:
            draftState.toasts.items.push(action.data);
            break;
        
        case TOASTS_REMOVE:
            draftState.toasts.items = draftState.toasts.items.filter(item => item.id !== action.data);
            break;

        default:

    }
})};

export default reducer;
