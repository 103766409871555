import { combineReducers } from 'redux';

import app from './app/reducer';
import store from './store/reducer';
import skus from './skus/reducer';
import items from './orders/reducer';
import auth from './auth/reducer'

export default combineReducers({
  auth,
  app,
  store,
  skus,
  items
});


