import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Grid} from '@mui/material';
import OrderHeader from './Header'
import ClienteHeader from './Cliente/Header'
import EnvioHeader from './Envio'
import Items from './Items'
import Cobros from './Cobros'
import Acciones from './Acciones'
import Totales from './Totales'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import * as ordersActions from '../../state/orders/actions' 
import Importes from './Importes';

const DatosPedidoLayout = () => {
  return (
    <Grid container spacing={1} sx={{ flexGrow: 1 }} >
      <Grid item xs={12} >
        <OrderHeader />
      </Grid>
      <Grid item xs={12} >
        <ClienteHeader />
      </Grid>  
      <Grid item xs={12} >
        <EnvioHeader />
      </Grid>             
      <Grid item xs={12}   >
        <Items />
      </Grid>
      <Grid item xs={12} >
        {/* <Cobros /> */}
      </Grid>            
    </Grid>
  )
}

const PedidoLayout = () => {

  const params = useParams();
  const nroPedido = parseInt(params.nroPedido)

  const dispatch = useDispatch();

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

  useEffect(() => {
    dispatch(ordersActions.initPedido())
    dispatch(ordersActions.setNroPedido(nroPedido))
    dispatch(ordersActions.getTiposFactura())
    dispatch(ordersActions.getCondicionesIva())
    
    dispatch(ordersActions.fetchConfiguracionPedido(nroPedido))
    dispatch(ordersActions.fetchDatosCliente(nroPedido))
    dispatch(ordersActions.fetchAll(nroPedido))

  },[])

  

  return (
    <div style={{height: 'calc(88vh)', overflow: 'scroll'}}>
      <Grid container spacing={1} s={12} direction="row" style={{ backgroundColor: '#f2f2f2', padding: 10 }}  >
        <Grid item xs={12} sm={12} lg={8}>
          <DatosPedidoLayout />
        </Grid>            
        <Grid item xs={12} sm={12} lg={4}>
          <Grid container direction="column" spacing={1} sx={{ flexGrow: 1 }} >
            <Grid item xs={12} sm={12} lg={4}>
              <Cobros nroPedido={nroPedido} />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
              <Importes  />
            </Grid>
            <Grid item xs={12} sm={12} lg={4}>
            <Totales />
            </Grid>
          </Grid>
        </Grid>            
      </Grid>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }} style={{ background: 'linear-gradient(to bottom, #999, #333'}}>
      <Toolbar>
        <Grid container sx={{ flexGrow: 1 }} >
          <Grid item xs={4} >
          </Grid>            
          <Grid item xs={4} >
          </Grid>            
          <Grid item xs={4} >
            <Acciones />  
          </Grid>
        </Grid>
      </Toolbar>
      </AppBar>      
    </div>
  );
}

export default PedidoLayout;
