import React, { useState, useEffect } from 'react';
import { Layout, Menu, Card, Switch } from 'antd';
import { Grid, InputAdornment, TextField , Box, Button } from '@mui/material';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import currencyFormat from '../../../utils/currencyFormat';
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditarClienteModal from './EditarClienteModal';
import NuevoPedidoModal from './NuevoPedidoModal';
import Api from '../../../state/Api';
import * as orderActions  from '../../../state/orders/actions';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const noMarginStyle ={
    marginTop : '0px',
    marginBottom : '0px'
}

const noPaddingTop = {
    paddingTop: '0px',
    paddingTop: '0px'
}

const rowIcon = {
    minWidth: '20px'
}

const Resultados = (props) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const allItems = props.results || [];
    const [editarClienteOpen, setEditarClienteOpen] = useState(false);
    const [nuevoPedidoModal, setNuevoPedidoModal] = useState(false);
    const [currentEditCliente, setCurrentEditCliente] = useState({});
    const [pedidos, setPedidos] = useState([]);
    const [rowSelectionModel, setRowSelectionModel] = React.useState ([0]);
    const apiRef = useGridApiRef();
    const [openLoader, setOpenLoader] =  React.useState(false);

    useEffect( () => {
        if(props.nuevo){
            setEditarClienteOpen(true)
            setCurrentEditCliente({ nuevo: true, RetieneIIBB : 'N' })   
        }
    }, [props.nuevo] )

    useEffect(() => {
        setPedidos([]);
        setRowSelectionModel([])
    }, [props.results])

    allItems.map( (item, index)=> {
       Object.assign(item, {id : index } )
    })

    const closeEditarCliente = () => {
        props.onCerroModal()
        setEditarClienteOpen(false)
    }

    const closeNuevoPedido = () => {
        setNuevoPedidoModal(false)
    }

    const handleCreoCliente = (cliente) => {
        setCurrentEditCliente(cliente)
    }

    const handleNuevoPedido = async(cliente) => {
        const { data } = await Api.req.post(`/orders/new`, { cliente: cliente  });
        dispatch(orderActions.setNewPedido(data.data[0].nropedido,cliente))
        history('/pedido/' + data.data[0].nropedido)
        /*let url = 'https://pixdev.tevecompras.com/#/' + data.data[0].nropedido + '/new';
        window.open(url, '_blank', 'noreferrer');*/
    }

    const handleOnCellClick = (params) => {
        getOrders(params.row.Tipo_Docu , params.row.Nro_Docu )
    };

    const getOrders = async (tipoDocu, nroDocumento) => {
        const { data } = await Api.req.get(`customers/${tipoDocu}/${nroDocumento}/orders`);
    
        setPedidos(data.items);
    }


    useEffect(()=>{
        if(props.buscando){ setOpenLoader(true) } else { setOpenLoader(false) }
    },[props.buscando])

    const columns = [
        {
            field: 'test', key: 'action', headerName: 'Accion', width: 100, sortable: false, renderCell: (params) => {
                const { row } = params;

                const editarCliente = async (e) => {
                    e.stopPropagation()
                    setCurrentEditCliente(Object.assign({}, row))
                    setEditarClienteOpen(true)
                };

                const nuevoPedido = (e) => {
                    e.stopPropagation()
                    handleNuevoPedido(Object.assign({}, row))
                };

                return (<><Button style={rowIcon} onClick={editarCliente} ><EditIcon /></Button><Button style={rowIcon} onClick={nuevoPedido} ><AddCircleOutlineIcon /></Button></>)
            }
        },
        { field: 'Nombres', headerName: 'Nombre', width: 200 , valueGetter: (params) =>  params.row.Nombres + ' ' + params.row.Apellido },
        { field: 'Direccion', headerName: 'Direccion', width : 200 , valueGetter: (params) =>  params.row.Calle + ' ' + params.row.Nro},
        { field: 'Localidad', headerName: 'Localidad', width: 200 , minValue: 1 },
        { field: 'Telefono', headerName: 'Telefono', width: 140 },
       
    ];


    const columnsPedidos = [
        {
            field: 'test', key: 'action', headerName: 'Accion', width: 70, sortable: false, renderCell: (params) => {
                const { row } = params;


                const nuevoPedido = (e) => {
                    e.stopPropagation()
                    handleNuevoPedido(Object.assign({}, row))
                };

                const handleAbrirPedido = (e) => {                    
                    let url = process.env.REACT_APP_ROUTE + '/#/pedido/' + row.nropedido;
                    e.stopPropagation();
                    window.open(url, '_blank', 'noreferrer')
                }

                return (<><Button onClick={handleAbrirPedido} ><AddIcon /></Button></>)
            }
        },
        { field: 'nropedido', headerName: 'Nropedido'},
        { field: 'importe', headerName: 'Importe'},
        { field: 'estado', headerName: 'Estado', minValue: 1 },
        { field: 'formaPago', headerName: 'Forma de pago', width: 120 },
        { field: 'fecha_alta', headerName: 'Fecha', width: 120, valueGetter: (params) => moment(params.row.fecha_alta).format('DD/MM/YYYY') },
        
    ];

    return (
        <div style={{ position : 'relative' }}>
        <Grid container spacing={0}  style={{ boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 10%)', border: '#ddd 1px solid', padding: 15, borderRadius: 8, backgroundColor: '#fff' }} >
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <NuevoPedidoModal onClose={closeNuevoPedido} open={nuevoPedidoModal} ></NuevoPedidoModal>
            <EditarClienteModal onCreoCliente={handleCreoCliente} currentEditCliente={currentEditCliente} onClose={closeEditarCliente} open={editarClienteOpen}></EditarClienteModal>
            <Grid item xs={12} >
                <h4 style={{marginTop: 0}}>
                    Resultados De Búsqueda
                </h4>
            </Grid>
            
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <h5 style={noMarginStyle}>Clientes</h5>
                </Grid>
                <Grid item  xs={6}>
                    <h5 style={noMarginStyle}>Pedidos</h5>
                </Grid>
                <Grid item style={noPaddingTop} xs={6}>
                    <DataGrid
                        style={{ flex: 1, height: 380, overflow: 'auto' }}
                        apiRef={apiRef}
                        rows={allItems}
                        columns={columns}
                        onCellClick={handleOnCellClick}
                        onSelectionModelChange={(newRowSelectionModel) => {
                            setRowSelectionModel(newRowSelectionModel);
                        }}
                        selectionModel={rowSelectionModel}
                        hideFooterPagination
                    >
                    </DataGrid>

                </Grid>
                <Grid item style={noPaddingTop} xs={6}>
                    <DataGrid
                        className="stripped"
                        style={{ flex: 1, height: 380, overflow: 'auto' }}
                        rows={pedidos}
                        getRowId={(row) => row.nropedido}
                        columns={columnsPedidos}
                        hideFooterPagination
                        disableSelectionOnClick 
                    >
                    </DataGrid>

                </Grid> 
            </Grid>
                     
            
        </Grid>
        </div>
        
    )
}

export default Resultados;