import React, { useState, useEffect } from 'react';
import { Menu, Typography, Box, Tabs, Tab, ButtonBase, Grid, TextField, Button, InputLabel } from '@mui/material';
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as itemsSelector from '../../../state/orders/selectors';
import Modal from '@mui/material/Modal';
import CobrosModal from './CobrosModal';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from "react-router-dom";
import * as ordersActions from '../../../state/orders/actions';
import * as storeSelector from '../../../state/store/selectors'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { getCurrentFormaPago } from './../../../state/orders/selectors';
import currencyFormat from '../../../utils/currencyFormat';
import RefreshIcon from '@mui/icons-material/Refresh';
import gridStyle from '../gridStyle';
import DeleteIcon from '@mui/icons-material/Delete';
import Api from '../../../state/Api';
import * as storeActions from '../../../state/store/actions';
import ConfirmDialog from '../../shared/confirmDialog';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
};

const modalStyle = {
    border : 'none'
}


const Cobros = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const nroPedido = parseInt(params.nroPedido)

    const [openModal, setOpenModal] = React.useState(false);
    const itemsSelected = useSelector(itemsSelector.fetchAllItems());
    const payments = useSelector(itemsSelector.getPayments());
    const hasItems = itemsSelected.length > 0 ? true : false;
    const allFormasDePago = useSelector(storeSelector.fetchFormasDePago());
    const isNewPedido = useSelector(itemsSelector.getNewPedido());
    const currentFormaPago = useSelector(itemsSelector.getCurrentFormaPago());
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [ deleteItemText , setDeleteItemText ] = React.useState('')
    const isFacturado = useSelector(itemsSelector.getPedidoFacturado());
    const tiene3payments = payments.length == 3 ? true : false;

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const handleCloseModalRefresh = () =>  { 
        setOpenModal(false)
        refreshPayments()
    };
    
    const handleChangeFormaPago = (event) => { 
        let formaPagoString = allFormasDePago.find((formaPago) => formaPago.Codigo == event.target.value).Descripcion;
        dispatch(ordersActions.setCurrentFormaPago(event.target.value, formaPagoString )) 
    };

    const openFormasDePago = () => {
        setOpenModal(true)
    }

    const [selectedRow, setSelectedRow] = React.useState();

    const [contextMenu, setContextMenu] = React.useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute('data-id')));
        if(!isFacturado){
            setContextMenu(
                contextMenu === null
                    ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                    : null,
            );
        }
    };

    const handleCloseContext = () => {
        setContextMenu(null);
    };

    const columns = [
        { field: 'formapago', headerName: 'FormaPago', width: 100 },
        { field: 'codAutorizacion', headerName: 'Cod Autorización', width: 200, flex: 1 },
        { field: 'Importe', headerName: 'Importe', align: 'right', width: 200, flex: 1, valueGetter: (params) => currencyFormat(params.row.Importe) } 
    ];

    const refreshPayments = () => {
        dispatch(ordersActions.getPayments(nroPedido))
    }

    const handleCloseUpdateModal = () => {
        dispatch(ordersActions.getPayments(nroPedido))
        setOpenModal(false);
    }

    const handleConfirmDeleteCobro = async () => {
        try {
            //throw new Error('Error data fetch');
            const { data } = await Api.req.delete(`/orders/order/${props.nroPedido}/payments/${selectedRow}`);
            refreshPayments()
            dispatch(storeActions.addToast('El cobro ha sido eliminado exitosamente'))
        } catch (error) {
            dispatch(storeActions.addToast('Ha habido un error al eliminar el cobro: ' + error, 'error'))

        }
    }

    const handleDeleteCobro = async () => {
        handleCloseContext()
        let payment = payments.find((payment) => payment.id == selectedRow)
        setDeleteItemText(payment);
        setOpenConfirmDelete(true)
     
    }

    return (
        <div style={{ postion : 'relative' }}>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CobrosModal closeModal={handleCloseModal} closeUpdateModal={handleCloseUpdateModal} nroPedido={props.nroPedido} ></CobrosModal>
                </Box>     
            </Modal>
            <ConfirmDialog
                title="Eliminar Cobro"
                open={openConfirmDelete}
                setOpen={setOpenConfirmDelete}
                onConfirm={handleConfirmDeleteCobro}
            >
                ¿Está seguro que de desea eliminar el cobro mediante {deleteItemText.formapago} de {currencyFormat(deleteItemText.Importe)} ?
            </ConfirmDialog>
            <Grid container spacing={0} direction="row" style={gridStyle} >
                <Grid item xs={12} >
                    <Box>
                        <InputLabel shrink htmlFor="select-multiple-native">
                            Forma de Pago
                        </InputLabel>
                        <Select
                            value={currentFormaPago}
                            onChange={handleChangeFormaPago}
                            size="small"
                            fullWidth
                            readOnly={!isNewPedido}
                            disabled={isFacturado}
                            variant="outlined"
                        >
                            {allFormasDePago && allFormasDePago.map((fp, index) => {
                                return (
                                    <MenuItem key={index} value={fp.Codigo}>{fp.Descripcion}</MenuItem>
                                )
                            })}
                        </Select>  
                    </Box>
                    {isNewPedido ? <>
                        <Button disabled={!hasItems || isFacturado || tiene3payments } onClick={openFormasDePago}>Agregar Cobro</Button>
                        <Button sx={{ float: 'right' }} onClick={refreshPayments}><RefreshIcon/></Button>
                        </> : ''  }
                    
                </Grid>
                <Grid item xs={12} >
                    <DataGrid
                        id="paymentsTable"
                        style={{ height: 300, overflow: 'auto' }}
                        rows={payments}
                        experimentalFeatures={{ newEditingApi: true }}
                        className="stripped"
                        columns={columns}
                        stripped
                        hideFooterPagination
                        disableSelectionOnClick
                        componentsProps={{
                            row: {
                                onContextMenu: handleContextMenu,
                                style: { cursor: 'context-menu' },
                            },
                        }}

                    />
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleCloseContext}
                        anchorReference="anchorPosition"
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                        componentsProps={{
                            root: {
                                onContextMenu: (e) => {
                                    e.preventDefault();
                                    handleCloseContext();
                                },
                            },
                        }}
                    >
                        <MenuItem onClick={handleDeleteCobro}> <DeleteIcon/> Eliminar Cobro</MenuItem>
                    </Menu>
                  
                </Grid>
            </Grid>
        </div>
    );
}

export default Cobros;