import React, { useEffect, useState } from 'react';
import { TextField, Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TruckIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Storefront';
import LocationIcon from '@mui/icons-material/FmdGood';
import { useDispatch, useSelector } from 'react-redux';
import * as itemsSelector from '../../../state/orders/selectors';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as itemsActions from '../../../state/orders/actions'
import EditIcon from '@mui/icons-material/Edit';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import dayjs from 'dayjs';
import Api from '../../../state/Api';
import ModalDireccion from './ModalDireccion';
import gridStyle from '../gridStyle';

const EnvioHeader = () => {
    const dispatch = useDispatch();
    const storeAddress = useSelector(itemsSelector.getStoreAddress());
    const datosCliente = useSelector(itemsSelector.getDatosCliente());
    const datosPedido = useSelector(itemsSelector.getDatosPedido());
    const tipoEnvioBoolean = useSelector(itemsSelector.getTipoEnvio()); 
    const tipoEnvio = tipoEnvioBoolean == true ? 'Retiro' : 'Envio';
    const [listadoProvincias, setListadoProvincias] = useState([]) 
    const [listadoPartidos, setListadoPartidos] = useState([]) 
    const [fechaEnvio, setFechaEnvio] = React.useState(dayjs());
    const [openModalAgree, setOpenModalAgree] = React.useState(false);
    const isFacturado = useSelector(itemsSelector.getPedidoFacturado())
    const handleChangeEnvio = (event, nextView) => {
        if(nextView){
            dispatch(itemsActions.setTipoEnvio(nextView))
        }
    };

    useEffect(() => {
        const fetchProvincias = async () => {
            let dataProv = await Api.req.get(`data/provincias`);
            setListadoProvincias(dataProv.data.data)
        }

        fetchProvincias()
    } , [] )

    useEffect(() => {
        const fetchPartidos = async () => {
            let dataPart = await Api.req.get(`data/provincia/${datosPedido.Provi_Entrega}/partidos`);
            setListadoPartidos(dataPart.data.data)
        }

        fetchPartidos()
    }, [datosPedido.Provi_Entrega])

    const getDomicilioEnvio = () => {

        if(tipoEnvio == 'Retiro'){
            return storeAddress;
        }else{
            var domicilioString;
            if (datosPedido.idProvi_Entrega == 'C') {
                let calle = datosPedido.Calle_Entrega ? datosPedido.Calle_Entrega : '';
                let nro = datosPedido.Nro_Entrega ? datosPedido.Nro_Entrega : '';
                let localidad = 'C.A.B.A.';
                domicilioString = calle + ' ' + nro + ' , ' + localidad;
            } else {
                let calle = datosPedido.Calle_Entrega ? datosPedido.Calle_Entrega : '';
                let nro = datosPedido.Nro_Entrega ? datosPedido.Nro_Entrega : '';
                let localidad = datosPedido.Loca_Entrega ? datosPedido.Loca_Entrega : '';
                let partido = datosPedido.Parti_Entrega ? datosPedido.Parti_Entrega : '';
                let provincia = datosPedido.Provi_Entrega ? datosPedido.Provi_Entrega : '';
                domicilioString = calle + ' ' + nro + ' , ' + localidad + ' ,  ' + partido + ' , ' + provincia;
            }
            return domicilioString;
        }

        
    }

    const handleOpenModalAgree = () => {
        setOpenModalAgree(true)
    }

    const handleCloseModalAgree = () => {
        setOpenModalAgree(false)
    }

  // 'linear-gradient(to bottom, #007bff, #4466f2'
  return (
      <Grid container spacing={0} style={gridStyle} >
        <ModalDireccion listadoProvincias={listadoProvincias} datosCliente={datosCliente} openModalAgree={openModalAgree} onCloseModalAgree={handleCloseModalAgree} ></ModalDireccion>
        <Grid container spacing={1}  direction="row" >
        <Grid item xs={12}>        
            <Grid container spacing={2} direction="row">
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Grid container>
                                <Grid item xs={4} >
                                    Método de Entrega
                                </Grid>   
                                <Grid item xs={8} >
                                    {tipoEnvio == 'Retiro' ? <HomeIcon sx={{ verticalAlign: 'bottom', marginRight: '5px' }} /> : <TruckIcon sx={{ verticalAlign: 'bottom', marginRight: '5px' }} /> }
                                    {tipoEnvio} - {getDomicilioEnvio()}
                                </Grid>   
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} >
                                    <Grid item xs={2}>
                                          <ToggleButtonGroup
                                              // orientation="vertical"
                                              value={tipoEnvio}
                                              exclusive
                                              onChange={handleChangeEnvio}
                                              size="small"
                                              fullWidth
                                              disabled={isFacturado}
                                          >
                                              <ToggleButton value="Retiro" aria-label="module">
                                                  <HomeIcon />
                                              </ToggleButton>
                                              <ToggleButton value="Envio" aria-label="envio" >
                                                  <TruckIcon />
                                              </ToggleButton>
                                          </ToggleButtonGroup>
                                    </Grid>
                                    <Grid item xs={6} >
                                      <TextField
                                          disabled={!!(tipoEnvio == 'Retiro')}
                                          label="Domicilio de Envío"
                                          variant="outlined"
                                          value={getDomicilioEnvio()}
                                          size="small"
                                          fullWidth
                                          InputProps={{
                                              startAdornment: (
                                                  <InputAdornment position="start">
                                                      <LocationIcon />
                                                  </InputAdornment>
                                              )
                                          }}
                                      />
                                    </Grid> 
                                    <Grid item xs={3}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    label="Fecha de envio"
                                                    value={fechaEnvio}
                                                    size="small"
                                                    minDate={dayjs()}
                                                    disabled={isFacturado}
                                                    inputFormat="DD/MM/YYYY"
                                                    onChange={(newValue) => {
                                                        setFechaEnvio(newValue);
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField size="small" {...params} />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={1}>
                                          <IconButton style={{ display : !tipoEnvioBoolean && !isFacturado ? 'block' : 'none'}} onClick={handleOpenModalAgree}>
                                              <EditIcon/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>                 
            </Grid> 
        </Grid>        
        </Grid>
    </Grid>                
    );
}

export default EnvioHeader;