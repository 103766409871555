import React, { useEffect, useState, useCallback} from 'react';
import { debounce } from "lodash";
import { styled } from '@mui/material/styles';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBack from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Box, Card, CardActionArea, CardMedia, CardContent, CardActions, InputAdornment } from '@mui/material';
import Search from '@mui/icons-material/Search';

import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as skusSelector from '../../state/skus/selectors';
import * as skusActions  from '../../state/skus/actions';
import * as appActions from '../../state/app/actions';

import Categories from './categories';
import currencyFormat from '../../utils/currencyFormat';

import { Routes, Route, useParams, useNavigate, useLocation  } from "react-router-dom";

const skus = [
  { id: 1, label: 'Snoww', firstName: 'Jon', age: 35 },
  { id: 2, label: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, label: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, label: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, label: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, label: 'Melisandre', firstName: null, age: 150 },
  { id: 7, label: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, label: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, label: 'Roxie', firstName: 'Harvey', age: 65 },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function Productos(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allSkus = useSelector(skusSelector.fetchAll());
  const [searchField,setSearchField] = useState('');
  let location = useLocation();
  const isCart =  location.state ? location.state.isCart : false; 

  useEffect(() => {
    dispatch(skusActions.fetchAll())
  },[])

  const request = debounce((value) => {
    setSearchField(value)
  }, 100);

  const debounceRequest = useCallback((value) => request(value), []);

  const updateSearch = (e) => {
    debounceRequest(e.target.value);
  }

  let filtered = allSkus.filter( sku => {

    if(sku.Descripcion){
      return sku.Descripcion.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 
    }else{
      return sku.Descripcion.toLowerCase().indexOf(searchField.toLowerCase()) !== -1 
    }
  })  


  return (

    <div style={{ height: 'calc(93vh)', width: '100%', overflow: 'auto', display : 'flex' , flexDirection: 'column' }}>
        <div sx={{ position: 'relative' }} style={{ background: 'linear-gradient(to bottom, #fff, #fff', padding: '6px 24px', borderRadius: 10, color: '#313131', boxShadow: '1px 5px 24px 0 rgb(68 102 242 / 5%)' }}>
        
          <Grid container>
            <Grid item xs={4} style={{ lineHeight : '54px'}}>
              <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <ArrowBack />
             
              </IconButton>
            <Typography sx={{ ml: 2, verticalAlign : 'middle' }} variant="h6" component="span">
              Productos
            </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign : 'center' }}>
              <TextField
                // label="Buscar" 
                autoFocus
                onChange={updateSearch}
                InputProps={{
                  startAdornment: (<InputAdornment position="start"> <Search /> </InputAdornment>),
                }}
              />
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </div>
        <Grid container style={{paddingTop: 20, display: 'none'}}>
          <Grid item xs={12}>
            {/* <Autocomplete
              // fullWidth
              freeSolo
              disablePortal
              autoHighlight
              id="combo-box-demo"
              getOptionLabel = {option => option.Descripcion}
              options={allSkus}
              // sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Producto" />}
            /> */}
            
          </Grid>
        </Grid>
        <Grid container style= {{padding: 20, flex : 1, overflowY : 'scroll'}}>
            {/* <Categories /> */}
            {filtered.map( sku => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} style={{padding: 10}} key={sku.PLU}>
                  
                  <Card sx={{  minHeight: 300, alignItems: 'space-between', borderRadius: 5, boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.16)' }} id={sku.id}
                    onClick={(e) => { navigate(`${sku.PLU}`, {state: {isCart: isCart }}) }}
                  >
                    <CardActionArea>
                      <CardMedia sx={{ flexGrow: 0, objectFit: 'scale-down'}}
                      component="img"
                      height="240"
                      image={sku.imageurl}
                      alt={sku.Descripcion}
                    />
                    <CardContent sx={{flexGrow: 1, minHeight: '80px'}}>
                      <Typography gutterBottom variant="h5" component="div" style={{textTransform: 'capitalize', fontSize : '16px'}}>
                        {sku.Descripcion}
                      </Typography>
                       

                    </CardContent>
                  <CardActions style={{ paddingTop : '0' }} sx={{flexGrow: 0, justifyContent: 'space-between', alignItems: 'flex-end', alignContent: 'flex-end', padding: 2}}>
                      <Box>
                          <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'left', color: '#6fbe22' }}>
                            {currencyFormat(sku.Precio_Contado)}
                          </Typography>   
                          <Typography gutterBottom variant="h5" component="div" style={{ textAlign: 'left', fontSize : '14px' }}>
                            18 cuotas de <span style={{ color: '#6fbe22' }}> {currencyFormat(sku.valorCuota)}</span>
                          </Typography>     
                      </Box>
      
                      {isCart ?     
                        <Button size="small" variant="outlined" color="info" sx={{borderRadius: 8, width: '150px', height: '40px', textAlign: 'right'}}>
                          AGREGAR
                        </Button>
                      : null }
                  </CardActions>
                  </CardActionArea>                    

                </Card>
              </Grid>
              )
            })}
          </Grid>
    </div>
  );
}

// export default Productos;