import React, { useEffect, useState } from 'react';
import { Box, Typography, Slide, Card, CardActionArea, CardMedia, CardContent, CardActions, Grid, AppBar, Toolbar, IconButton, Button } from '@mui/material';
import { List, ListItem, ListItemText, ListItemAvatar, ListItemButton, ListItemIcon, Avatar, Divider, Tooltip } from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Star from '@mui/icons-material/Star';

import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as skusSelector from '../../state/skus/selectors';
import * as skusActions  from '../../state/skus/actions';
import * as itemsSelector  from '../../state/orders/selectors';
import * as orderSelector  from '../../state/orders/selectors';
import * as orderActions  from '../../state/orders/actions';
import * as storeActions  from '../../state/store/actions';
import * as storeSelector  from '../../state/store/selectors';
import Chip from '@mui/material/Chip';
import Categories from './categories';
import Filters from './Filters';
import currencyFormat from '../../utils/currencyFormat';

import { Routes, Route, useParams, useNavigate, useLocation, Navigate  } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductDetails(props) {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  const skuId = parseInt(params.skuId)
  const [ disableCuotasFilter, setDisableCuotasFilter ] = useState(false);
  const [ disableListasFilter, setDisableListasFilter ] = useState(false);
  const nroPedido = useSelector(itemsSelector.getNroPedido());
  const skuDetails = useSelector(skusSelector.getSkuDetails(skuId));
  const currentLista = useSelector(itemsSelector.getCurrentLista())
  const currentCuotas = useSelector(itemsSelector.getCuotas())
  const allPriceLists = useSelector(skusSelector.fetchPriceListsBySku(skuId,currentLista,disableListasFilter));
  const allFormasDePago = useSelector(storeSelector.fetchFormasDePago());
  const [installments, setInstallments] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedLista, setSelectedLista] = React.useState('SIN ESPECIFICAR PESOS');
  let currentSelected = currentLista && !disableListasFilter ? currentLista : selectedLista;
  const allPrices = useSelector(skusSelector.fetchPricesBySku(skuId, currentSelected , currentCuotas, disableCuotasFilter ));
  const allItems = useSelector(itemsSelector.fetchAllItems());

  const secondItem = allItems.length > 0 ? true : false;

  const grupoFilterd = allPrices.filter((item) => item.nombreGrupo == currentLista)


  const handleListaClick = (event, index, sku) => {
    dispatch(skusActions.fetchPricesBySku(skuId, sku.nombreGrupo))
    setSelectedIndex(index);
    setSelectedLista(sku.nombreGrupo);
    setInstallments(sku.installments);
  };


  const handleBack = () => {
    console.log(props.isModal)
    if(props.isModal){
      history(-1)
    }else{
      history('/skus')
    }
    
  }
  

  const routeImages = "https://pixdev.tevecompras.com/assets/";
  const itemsCount = useSelector(orderSelector.getItemsCount());
  const isCart =  useSelector(orderSelector.getIsCart()); //location.state ? location.state.isCart : false; 

  const handleCuotaSelection = (event, cuota, sku)  => {
    

    if(isCart){
      let posiblesCuotas = allPrices.map((price) => price.cuotasMin);
      let formapagoString = allFormasDePago.find((formaPago) => formaPago.Codigo == cuota.formaPago ).Descripcion;
      dispatch(orderActions.setCurrentCuotas(posiblesCuotas))
      dispatch(orderActions.setCurrentLista(cuota.nombreGrupo))
      dispatch(orderActions.setCurrentFormaPago(cuota.formaPago, formapagoString))
      dispatch(orderActions.addToCart({
        empresa: 10,
        sucursal: 11,
        nropedido: nroPedido,
        orden: itemsCount+1,
        plu: cuota.PLU,
        cantidad: 1,
        precioLista: cuota.PrecioContado,
        plista: cuota.PrecioContado,
        cuotas: cuota.cuotasMin,
        descuento: 0,
        tea: cuota.TEA,
        tasaIVA: sku.TasaIVA,
        porcentajeRetencion: 0,
        iibb: false,
        tipo: sku.Tipo,
        cuotasSinInteres: cuota.CuotasSinInteres,
        medioTarjeta: cuota.Medio_Tarjeta,
        valorSele: cuota.valorCuota,
        PTFSele: cuota.PTF,
        IIBBLista: 0,
        importeIIBB: 0,
        operador: 'LOCALF01',
        devuelve: 0,
        exento: false,
        afectaStock: sku.Afecta_Stock,
        peso: sku.Peso,
        baja: false,
        puntaje: sku.puntaje,
        precioListaComision: cuota.Precio_Lista
      })).then( () => {
        if(props.isModal){
          props.handleClose()
          dispatch(storeActions.addToast('Se ha guarado el sku en el listado de productos'))
   
          dispatch(orderActions.fetchAll(nroPedido))
        }
        
      }) 
      // dispatch(orderActions.closeSKUDrawer())
    }
  }

  const handleDisableCuotasFilter = () => {
    setDisableCuotasFilter(true)
  }

  const handleDisableListasFilter = () => {
    setDisableListasFilter(true)
  }


  let location = useLocation();

  useEffect(() => {
    dispatch(skusActions.fetchPriceListsBySku(skuId)).then(
      () => {
        let runSelected = currentLista ? currentLista : selectedLista;
        dispatch(skusActions.fetchPricesBySku(skuId, runSelected))
      })
  },[])

  return (
    <div style={{height: 'calc(93vh)' ,overflowY: 'auto', overflowX: 'hidden'}} TransitionComponent={Transition}>
        <Grid container spacing={1} direction="row" style={{ height: 'calc(10vh)', margin: 1, padding: 1, minHeight: '165px', backgroundColor: '#fff'}} >
            <Grid item xs={0.5} style={{paddingLeft: 20, marginTop: 0}}>
                <IconButton
                edge="start"
                color="secondary"
                onClick={ handleBack }
                aria-label="close"
                variant="contained"
                >
                <ArrowBack />
                </IconButton>
            </Grid> 
          <Grid item xs={2} style={{paddingLeft: 20, marginTop: 0}}>
            <img style={{ width : '150px'}} src={skuDetails.imageurl} />
          </Grid>    
          <Grid item xs={9.5}>    
            <Grid container spacing={1} direction="column" >
                <Grid item xs={12}>                  
                    <Typography sx={{ ml: 0, flex: 1 }}  variant="h5" component="div" style={{textAlign: 'left', fontWeight: 'bold'}}>
                    {skuDetails.PLU} - {skuDetails.Descripcion}
                    </Typography>          
                </Grid>
                <Grid item xs={12}>  
                    <Grid container spacing={2} direction="row" >
                        {/* <Grid item xs={7} sm={0.1}>
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left', overflow: 'hidden'}}>
                            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur...
                            </Typography>
                        </Grid> */}
                        <Grid item xs={2} sm={4}>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Ancho: {(skuDetails.Ancho*100).toFixed(2)} cm. 
                            </Typography>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Largo: {(skuDetails.Largo*100).toFixed(2)} cm. 
                            </Typography>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Alto: {(skuDetails.Alto*100).toFixed(2)} cm.
                            </Typography>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Peso: {(skuDetails.Peso).toFixed(2)} kg.
                            </Typography> 
                        </Grid>
                        <Grid item xs={3} sm={4}>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Stock Real: {(skuDetails.Stock_Real)}
                            </Typography>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Stock Comprometido:  0
                            </Typography>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Stock Reservado: 0 
                            </Typography>  
                            <Typography sx={{ ml: 0, flex: 1 }}  variant="p" component="div" style={{textAlign: 'left'}}>
                                Sin Stock: x
                            </Typography>                                                              
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>   
        </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={1} direction="row" style={{paddingLeft: 10}} >
        <Grid item xs={6} style={{ overflow: 'auto', height: 'calc(76vh)', paddingTop: '10px' }}>  
          <Filters disableCuotasFilter={disableCuotasFilter} disableListaFilter={disableListasFilter} onDisableCuotasFilter={handleDisableCuotasFilter} onDisableListaFilter={handleDisableListasFilter} currentCuotas={currentCuotas} currentLista={currentLista}></Filters>
            <Typography  variant="h6" component="div" style={{textAlign: 'left', margin: 10, minWidth: '35px'}}>
            Listas de Precios
                    </Typography>
            <List component="nav" sx={{ width: '100%', padding: 0, bgcolor: 'background.paper' }}>
        { allPriceLists.map( (sku, index) => {
            return(
              <ListItem key={sku.id} 
              style={{ padding: 0}}
                secondaryAction={<ChevronRight />}
              >
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={(event) => handleListaClick(event, index, sku)}
                >
                  <ListItemIcon>
                    <Star sx={{ color: sku.destacado ? '#f50057' : null}}/>
                  </ListItemIcon>
                  <ListItemAvatar>
                    <img alt={sku.nombreGrupo} width={100} src={routeImages + sku.logo} />
                  </ListItemAvatar>
                <ListItemText primary={sku.nombreGrupo} secondary={<Grid container><Grid item xs={6}>{currencyFormat(sku.Precio_Lista)}</Grid><Grid item xs={6}>TEA: {sku.TEA}%</Grid></Grid>} />
                </ListItemButton>                
                {/* <Divider variant="inset" component="li" /> */}
              </ListItem>
            )
        })}
          </List>
        </Grid>
        <Grid item xs={6} 
            // style={{backgroundColor: 'aliceblue'}} 
        >
        <Grid container style={{padding: 10, overflow: 'auto'}}>
        { allPrices.map( (cuotas, index, arr) => {

            const isGroupChange = arr[index].nombre != arr[index > 0 ? index - 1 : index].nombre
            
            return(
                <>
                { (isGroupChange || index === 0) ?
                    <Typography  variant="h6" component="div" style={{textAlign: 'left', marginTop: 10, minWidth: '35px'}}>
                        {cuotas.nombre}
                    </Typography>
                : null
                }                
              <Tooltip title={ cuotas.Vendible =='N' ? 'NO VENDIBLE' : '' }>
              <Card sx={{ width: '100%', marginTop: 1, marginRight: 1, alignItems: 'space-between', borderRadius: 5, boxShadow: '0 0 13px 0 rgba(0, 0, 0, 0.16)', cursor: cuotas.Vendible =='N' ? 'not-allowed' : 'pointer', pointerEvents: cuotas.Vendible =='N' ? 'none' : 'initial', opacity: cuotas.Vendible =='N' ? 0.50 : 1 }} 
              // onClick={(e) => { navigate(`/skus/${sku.PLU}`) }}
              >
              <CardActionArea onClick={(e) => {
                handleCuotaSelection(e, cuotas, skuDetails)
              }}>
              <CardContent sx={{flexGrow: 0,  flexDirection: 'column', paddingBottom: 1}}>
              <Grid container spacing={1} direction="row" style={{width: '100%'}}>
                <Grid item xs={3} >
                    <Grid container direction="row" spacing={1} style={{paddingTop: 1, paddingLeft: 10, justifyContent: 'space-around'}}>
                    <Typography  variant="h4" component="div" style={{ textAlign: 'right', minWidth: '35px'}}>
                    {cuotas.cuotasMin}
                    </Typography>
                    <Typography variant="h6" component="div" style={{paddingTop: 8,fontWeight: 'bold', color: 'gray', textAlign: 'center', minWidth: '35px'}}>
                      x
                    </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={5} >
                  <Typography  variant="h5" component="div" style={{  textAlign: 'left', minWidth: '150px', color: 'green' }}>
                    { currencyFormat(cuotas.valorCuota) }
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                  <span style={{ color: cuotas.rTEA > 0 ? 'black' : 'black'}}>
                     { cuotas.esTEA ? 'TEA: ' : 'CFT: ' }
                    </span> 
                    <span style={{ color: cuotas.rTEA > 0 ? 'red' : 'green'}}>{cuotas.rTEA}%</span><br />
                  <Typography gutterBottom variant="p" component="div" style={{textTransform: 'capitalize', textAlign: 'right', fontWeight: 600, fontSize: 16, minWidth: '120px'}}>
                  { currencyFormat(cuotas.PTF) }
                  </Typography>
                </Grid>
              </Grid>
              </CardContent>
            </CardActionArea>  
            </Card>
            </Tooltip>
            </>
            )
          })}
          </Grid>
        </Grid>
    </Grid>    
    </div>
  );
}


