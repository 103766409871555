import React from 'react';
import Chip from '@mui/material/Chip';
export default function Filters (props) {

    const handleRemoveCuotasFilter = () => {
        props.onDisableCuotasFilter()
    }

    const handleRemoveListaFilter = () => {
        props.onDisableListaFilter()
    }

    return (<>
            { props.currentLista && !props.disableListaFilter ? <Chip label={props.currentLista} variant="outlined" onDelete={handleRemoveListaFilter} /> : ''  }
            { props.currentCuotas && !props.disableCuotasFilter  ? <Chip label={props.currentCuotas + ' cuotas'} variant="outlined" onDelete={handleRemoveCuotasFilter} /> : '' }
        </>
    )
}

 