import React, { useState, useEffect } from 'react';
import { Layout, Menu, Card, Switch } from 'antd';
import { Grid, InputAdornment, TextField , Box, Button } from '@mui/material';
import Api from '../../../state/Api';
import Form from 'antd';

const { Header, Content, Footer } = Layout;


const textFieldStyle = { marginRight : '10px' }

const Busqueda = (props) => {
    const [ apellido, setApelido] = useState('');
    const [ nombres, setNombres] = useState('');
    const [ nroDocu, setNroDocu] = useState('');
    const [ telefono, setTelefono] = useState('');
    const [disabledButton, setDisabledButton ] = useState(false);

    const buscar = async () => {
        let paramsQuery = {};
        props.onBusquedaInit()
        setDisabledButton(true)
        if(apellido){ paramsQuery.apellido = apellido };
        if(nombres) { paramsQuery.nombres = nombres };
        if(nroDocu){ paramsQuery.nroDocu = nroDocu };
        if(telefono) { paramsQuery.telefono = telefono }
        const { data } = await Api.req.get(`/customers/search`,{
            params:paramsQuery,
        });
        setDisabledButton(false)
        props.onBusquedaResult(data.items)
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (e.key === 'Enter') {
            console.log(apellido)
            e.preventDefault();
        }
       
    };

    const checkDisabled = () => {
        if (!!!apellido && !!!nombres && !!!nroDocu && !!!telefono ){
            return true;
        }else{
            return false;
        }   
    }


    return (
        <form onSubmit={handleSubmit}>
        <Grid container spacing={0}  s={12} style={{ boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 10%)', border: '#ddd 1px solid', padding: 15, borderRadius: 8, backgroundColor: '#fff' }} >
            
                    <Grid item xs={12}>
                        <h5>Busqueda de Clientes</h5>
                    </Grid>
                    <Grid item xs={8}>
                    
                            <TextField
                                value={apellido} 
                                onChange={(e) => setApelido(e.target.value)}
                                id="apellido"
                                label="Apellido"

                                style={textFieldStyle}
                            />
                            <TextField
                                value={nombres} 
                                onChange={(e) => setNombres(e.target.value)}
                                id="nombres"
                                label="Nombres"
                                style={textFieldStyle}
                            />
                            <TextField
                                value={nroDocu} 
                                onChange={(e) => setNroDocu(e.target.value)}
                                id="nroDocu"
                                label="Nro Documento"
                                style={textFieldStyle}
                            />

                            <TextField
                                value={telefono}
                                onChange={(e) => setTelefono(e.target.value)} 
                                id="telefono"
                                label="Teléfono"
                            />
                    </Grid>
                    <Grid item xs={4} >
                        <Button type="submit" disabled={checkDisabled() || disabledButton} onClick={buscar} variant="contained">Buscar</Button>
                    </Grid>
                
            

        </Grid>
        </form>
    )
}

export default Busqueda;