export const ADD = 'items/ADD';
export const ADD_ITEM_SUCCESS = 'items/ADD_ITEMS_SUCCESS';
export const FETCH = 'items/GET';
export const FETCH_ITEMS_SUCCESS = 'items/FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'items/FETCH_ITEMS_FAILURE';

export const UPDATE_ITEM_REQUEST = 'items/UPDATE_ITEM_REQUEST';
export const UPDATE_ITEM_SUCCESS = 'items/UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'items/UPDATE_ITEM_FAILURE';

export const UPDATE_CUOTAS_REQUEST = 'items/UPDATE_CUOTAS_REQUEST';
export const UPDATE_CUOTAS_SUCCESS = 'items/UPDATE_CUOTAS_SUCCESS';
export const UPDATE_CUOTAS_FAILURE = 'items/UPDATE_CUOTAS_FAILURE';

export const FETCH_DATOS_CLIENTE_REQUEST = 'items/FETCH_DATOS_CLIENTE_REQUEST';
export const FETCH_DATOS_CLIENTE_SUCCESS = 'items/FETCH_DATOS_CLIENTE_SUCCESS';
export const FETCH_DATOS_CLIENTE_FAILURE = 'items/FETCH_DATOS_CLIENTE_FAILURE';

export const FETCH_DATOS_PEDIDO_REQUEST = 'items/FETCH_DATOS_PEDIDO_REQUEST';
export const FETCH_DATOS_PEDIDO_SUCCESS = 'items/FETCH_DATOS_PEDIDO_SUCCESS';
export const FETCH_DATOS_PEDIDO_FAILURE = 'items/FETCH_DATOS_PEDIDO_FAILURE';

export const FETCH_PAYMENTS_PEDIDO_REQUEST = 'items/FETCH_PAYMENTS_PEDIDO_REQUEST';
export const FETCH_PAYMENTS_PEDIDO_SUCCESS = 'items/FETCH_PAYMENTS_PEDIDO_SUCCESS';
export const FETCH_PAYMENTS_PEDIDO_FAILURE = 'items/FETCH_PAYMENTS_PEDIDO_FAILURE';

export const SET_NRO_PEDIDO = 'items/SET_NRO_PEDIDO';

export const SET_CURRENT_LISTA = 'items/SET_CURRENT_LISTA';
export const SET_TIPO_ENVIO = 'items/SET_TIPO_ENVIO';
export const SET_CURRENT_LISTA_CUOTAS = 'items/SET_CURRENT_LISTA_CUOTAS';

export const SET_CURRENT_FORMAPAGO = 'items/SET_CURRENT_FORMAPAGO';

export const SET_TIPOS_FACTURA = 'items/SET_TIPOS_FACTURA';
export const SET_TIPO_FACTURA = 'items/SET_TIPO_FACTURA';

export const SET_CONDICIONES_IVA = 'items/SET_CONDICIONES_IVA';
export const SET_CONDICION_IVA = 'items/SET_CONDICION_IVA';

export const SET_NEW_PEDIDO = 'items/SET_NEW_PEDIDO';
export const DELETE_NEW_PEDIDO = 'items/DELETE_NEW_PEDIDO';
export const INIT_PEDIDO = 'items/INIT_PEDIDO';
export const RESET_FILTROS = 'items/RESET_FILTROS';

export const OPENDRAWER = 'items/OPENDRAWER';
export const CLOSEDRAWER = 'items/CLOSEDRAWER';

export const FETCH_STORE_ADDRESS_REQUEST = 'items/FETCH_STORE_ADDRESS_REQUEST';
export const FETCH_STORE_ADDRESS_SUCCESS = 'items/FETCH_STORE_ADDRESS_SUCCESS';
export const FETCH_STORE_ADDRESS_FAILURE = 'items/FETCH_STORE_ADDRESS_FAILURE';

export const DELETE_ITEM_REQUEST = 'items/DELETE_ITEM_REQUEST';
export const DELETE_ITEM_SUCCESS = 'items/DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAILURE = 'items/DELETE_ITEM_FAILURE';

export const SET_ADDRESS_DATA = 'items/SET_ADDRESS_DATA';
export const SET_OPERADOR = 'items/SET_OPERADOR';
export const SET_MEDIO = 'items/SET_MEDIO';

export const SET_NRO_FACTURA = 'items/SET_NRO_FACTURA';
export const SET_PEDIDO_FACTURADO = 'items/SET_PEDIDO_FACTURADO';

export const SET_IMPORTE = 'items/SET_IMPORTE';
