import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody , FormHelperText, InputLabel, FormControl, Select, MenuItem , Button, DialogActions, RadioGroup, Radio, Dialog , DialogTitle, DialogContent, DialogContentText, Grid,FormLabel, FormControlLabel, TextField } from '@mui/material';
import Api from '../../../state/Api';
import * as storeActions from '../../../state/store/actions';
import * as orderActions from '../../../state/orders/actions';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import NuevoTelefonoModal from './NuevoTelefonoModal';
import * as itemsSelector from '../../../state/orders/selectors';

const EditarClienteModal = (props) => {
    const dispatch = useDispatch();

    const handleClose = () => {
        props.onClose()
    }
    const [Tipo_Docu,setTipo_Docu] = useState('');
    const [Nro_Docu,setNro_Docu] = useState('');
    const [Mayorista, setMayorista] = useState('');
    const [CUIT, setCUIT] = useState('');
    const [Nombres, setNombres] = useState('');
    const [Apellido, setApellido] = useState('');
    const [Calle, setCalle] = useState('');
    const [Nro, setNro] = useState('');
    const [Edif, setEdif] = useState('');
    const [CP, setCp] = useState('');
    const [Provincia, setProvincia] = useState('');
    const [Localidad, setLocalidad] = useState('');
    const [Partido, setPartido] = useState('');
    const [E_Mail, setE_Mail] = useState('');
    const [Fecha_Nacim, setFecha_Nacim] = useState('');
    const [edad, setEdad] = useState('');
    const [Sexo, setSexo] = useState('');
    const [RetieneIIBB, setIIBB] = useState('');
    const [SituacionIIBB, setSituacionIIBB] = useState('');
    const [tipoDocumentos,setTipoDocumentos] = useState([])
    const [listadoProvincias,setListadoProvincias] = useState([])
    const [listadoPartidos, setListadoPartidos] = useState([])
    const [listadoLocalidades, setListadoLocalidades] = useState([])
    const [listadoIIBB,setListadoIIBB] = useState([])

    const [values , setFieldValues] = useState(null)

    const [validForm, setValidForm] = useState(false)
    const [errors, setErrors] = useState([]);
    const [telefonos,setTelefonos] = useState([])
    const [nuevoTelefonoOpen, setNuevoTelefonoOpen] = useState(false)
    const nroPedido = useSelector(itemsSelector.getNroPedido())
    

    useEffect(
         () => {
            const fetchData = async () =>{

                let dataPart = await Api.req.get(`data/provincia/${props.currentEditCliente.Provincia}/partidos`);
                setListadoPartidos(dataPart.data.data)


                if (props.currentEditCliente.Provincia == 'C' || props.currentEditCliente.idProvincia == 'C'){
                    setListadoLocalidades([{Codigo : 'CAP', Descripcion : 'Capital Federal'}])
                    setLocalidad('CAP')
                }else{
                    let dataLoc = await Api.req.get(`data/partido/${props.currentEditCliente.Partido}/localidades`);
                    setListadoLocalidades(dataLoc.data.data)
                }
                
            }

            const fetchDataNuevo = async () => {
                let dataDoc = await Api.req.get(`data/documentos`);
                setTipoDocumentos(dataDoc.data.data)

                let dataProv = await Api.req.get(`data/provincias`);
                setListadoProvincias(dataProv.data.data)
            }

            const fetchDataTelefonos = async () => {
                let dataTelefonos = await Api.req.get(`customers/${props.currentEditCliente.Tipo_Docu}/${props.currentEditCliente.Nro_Docu}/telefonos`);
                setTelefonos(dataTelefonos.data.items)
            }

            const fetchDataIIBB = async () => {
                let dataIIBB = await Api.req.get(`/data/situacioniibb`);
                setListadoIIBB(dataIIBB.data.data)
            }


            setFieldValues(props.currentEditCliente)

            setTipo_Docu(props.currentEditCliente.Tipo_Docu || 1)
            setNro_Docu(props.currentEditCliente.Nro_Docu || '')
            setMayorista(props.currentEditCliente.Mayorista || '')
            setCUIT(props.currentEditCliente.CUIT || '')
            setNombres(props.currentEditCliente.Nombres || '')
            setApellido(props.currentEditCliente.Apellido || '')

            setCalle(props.currentEditCliente.Calle || '')
            setNro(props.currentEditCliente.Nro || '')
            setEdif(props.currentEditCliente.Edif || '')
            setCp(props.currentEditCliente.CP || '')


            if (props.currentEditCliente.idProvincia){
                setProvincia(props.currentEditCliente.idProvincia || '')
            }else{
                setProvincia(props.currentEditCliente.Provincia || '')
            }
           
            setLocalidad(props.currentEditCliente.Localidad || '')
            setPartido(props.currentEditCliente.Partido || '')
            
            setE_Mail(props.currentEditCliente.E_Mail || '')

            if (props.currentEditCliente.Fecha_Nacimiento){
                setFecha_Nacim(props.currentEditCliente.Fecha_Nacimiento ? new Date(props.currentEditCliente.Fecha_Nacimiento) : '')
            }else{
                setFecha_Nacim(props.currentEditCliente.Fecha_Nacim ? new Date(props.currentEditCliente.Fecha_Nacim) : '')
            }
            
            setSexo(props.currentEditCliente.Sexo || '')
            setIIBB( String(props.currentEditCliente.RetieneIIBB) || '')
          

            if (props.currentEditCliente && !props.currentEditCliente.nuevo && Object.keys(props.currentEditCliente).length !== 0 ){
                fetchDataNuevo()
                fetchData()
                fetchDataTelefonos()
                fetchDataIIBB()
            }

            if (props.currentEditCliente.nuevo){
                fetchDataNuevo();
                fetchDataIIBB()
            }
           
         },[props.currentEditCliente]
    )

    const refreshTelefonos = async () => {
        let dataTelefonos = await Api.req.get(`customers/${Tipo_Docu}/${Nro_Docu}/telefonos`);
        setTelefonos(dataTelefonos.data.items)
    }

    useEffect(()=> {
        validateSelects()
        
    },[Provincia ,Partido,Localidad, Mayorista,Nro_Docu, CUIT, Nombres, Apellido, Calle, Nro, Edif, CP, E_Mail, Fecha_Nacim, Sexo, RetieneIIBB ])

    const validateSelects = (fieldValues = values) => {
        let tmp = {}
        tmp.Nro_Docu = Nro_Docu.length > 0 ? '' : 'Agregue Nùmero';
        tmp.Mayorista = Mayorista ? '' : 'Agregue Mayorista';
        tmp.Provincia = Provincia ? '' : 'Agregue Provincia';
        tmp.Partido = Provincia == 'C' || Partido ? '' : 'Agregue Partido';
        tmp.Localidad = Localidad ? '' : 'Agregue Localidad';
        tmp.CUIT = CUIT.length != 0 ? '' : 'Agregue CUIT';
        tmp.Nombres = Nombres.length != 0 ? '' : 'Agregue Nombre';
        tmp.Apellido = Apellido.length != 0 ? '' : 'Agregue Apellido';

        tmp.Calle = Calle.length != 0 ? '' : 'Agregue Calle';
        tmp.Nro = Nro.length != 0 ? '' : 'Agregue Nùmero Calle';
        tmp.Edif = Edif.length != 0 ? '' : 'Agregue Edificio';
        tmp.CP = CP.length != 0 ? '' : 'Agregue CP';

        tmp.E_Mail = /^\S+@\S+\.\S+$/.test(E_Mail) ? '' : 'Ingrese un email válido';
        tmp.Fecha_Nacim = Fecha_Nacim.length != 0 ? '' : 'Agregue Fecha Nac';
        tmp.Sexo = Sexo.length != 0 ? '' : 'Agregue Sexo';
        tmp.RetieneIIBB = RetieneIIBB.length != 0 ? '' : 'Agregue IIBB';
        if(RetieneIIBB == 'true'){
            tmp.SituacionIIBB = SituacionIIBB.length != 0 ? '' : 'Agregue IIBB';
        }
       

        setErrors({...tmp})


        return Object.values(tmp).every( x => x == '')
    
    }

    const handleAddTelefono = async () => {
        setNuevoTelefonoOpen(true)
    }

    const cambioProvincia = async (e) => {

        if(e.target.value == 'C'){
            setListadoPartidos([])
            setListadoLocalidades([{Codigo : 'CAP', Descripcion : 'Capital Federal'}])
            setLocalidad('CAP')
            setPartido('');
          
            setProvincia(e.target.value)
        }else{
            let partidos = await Api.req.get(`data/provincia/${e.target.value}/partidos`);
            setListadoPartidos(partidos.data.data)
            setProvincia(e.target.value)
            setLocalidad('')
            setPartido('');
            setListadoLocalidades([])
        }   
    }

    const cambioPartido = async (e) => {

        if(e.target.value){
            let localidades = await Api.req.get(`data/partido/${e.target.value}/localidades`);
            setListadoLocalidades(localidades.data.data)
            setPartido(e.target.value )

        }
       
    }

    const handleCloseNuevo = () => {
        setNuevoTelefonoOpen(false)
    }


    const getValidForm = () => {
        return Object.values(errors).every( x => x == '');
    }

    const cambioLocalidad = async (e) => {
        setLocalidad(e.target.value)
    }

    const saveCliente = async () => {
        if(props.currentEditCliente.nuevo){
            saveNewCliente()
        }else{
            updateCliente()
        }
    }

    const saveNewCliente = async () => {
        const { data } = await Api.req.post(`customers/new`,{
            "Tipo_Docu": Tipo_Docu,
            "Nro_Docu": Nro_Docu,
            "Apellido": Apellido,
            "Nombres" : Nombres,
            "CUIT" : CUIT,
            "Calle": Calle,
            "Nro": Number(Nro),
            "Edif": Edif,
            "CP": Number(CP),
            "Localidad": Localidad,
            "Partido": Partido,
            "Fecha_Nacim" : Fecha_Nacim ,
            "Provincia": Provincia,
            "SituacionIIBB": Number(SituacionIIBB),
            "RetieneIIBB": RetieneIIBB,
            "Sexo": Sexo,
            "E_Mail": E_Mail,
            "Mayorista": Mayorista
        });
        dispatch(storeActions.addToast('Se ha creado el cliente exitosamente'))
        props.onClose()
    }

    const updateCliente = async () => {
        let datosEdit = {
            "Apellido": Apellido,
            "Nombres": Nombres,
            "CUIT": CUIT,
            "Calle": Calle,
            "Nro": Number(Nro),
            "Edif": Edif,
            "CP": Number(CP),
            "Localidad": Localidad,
            "Partido": Partido,
            "Fecha_Nacim": Fecha_Nacim,
            "Provincia": Provincia,
            "SituacionIIBB": Number(SituacionIIBB),
            "RetieneIIBB": RetieneIIBB,
            "Sexo": Sexo,
            "E_Mail": E_Mail,
            "Mayorista": Mayorista
        }
        const { data } = await Api.req.put(`/customers/${Tipo_Docu}/${Nro_Docu}`, datosEdit );
        dispatch(storeActions.addToast('Se ha modificado el cliente'))
        if(props.enPedido){
            dispatch(orderActions.fetchDatosCliente(nroPedido)) 
        }
    }

    return(
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
        >
        <NuevoTelefonoModal handleRefreshTelefonos={refreshTelefonos} tipo={Tipo_Docu} nro_docu={Nro_Docu} onClose={handleCloseNuevo} open={nuevoTelefonoOpen} ></NuevoTelefonoModal>
        <DialogTitle>{ props.currentEditCliente.nuevo ? 'Nuevo Cliente' : 'Editar Cliente' }</DialogTitle>
        <DialogContent>
            <h3>Datos Básicos</h3>
            
            <Grid container rowSpacing={1}>
                <Grid item sm={3} >
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth={true}
                        value={Tipo_Docu}
                        label="Tipo Docu"
                        onChange={(e) => setTipo_Docu(e.target.value)}
            
                    >
                        {tipoDocumentos ? tipoDocumentos.map(
                            (item, index)=> (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                        ) : ''}
                    </Select>

                </Grid>
                <Grid item sm={3} >
                    <TextField
                        value={Nro_Docu} 
                        onChange={(e) => setNro_Docu(e.target.value)}
                        id="numero"
                        label="Numero"
                        {...(errors.Nro_Docu && { error:true, helperText : errors.Nro_Docu }) }
                    />
                </Grid>
                <Grid item sm={3} >
                <FormControl error={!!errors.Mayorista} required fullWidth={true} sx={{ minWidth: 120 }}>
                    <InputLabel id="partido-label">Mayorista</InputLabel>
                    <Select
                            labelId="demo-simple-select-label"
                            id="demo-mayorista"
                            fullWidth={true}
                            value={Mayorista}
                            label="Mayorista"
                            onChange={(e) => setMayorista(e.target.value)}
                        >
                            <MenuItem value='S'>S</MenuItem>
                            <MenuItem value='N'>N</MenuItem>
                        </Select>
                        <FormHelperText>{errors.Mayorista? errors.Mayorista : ''}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item sm={3} >
                    <TextField
                        value={CUIT} 
                        onChange={(e) => setCUIT(e.target.value)}
                        id="cuil"
                        label="CUIT/CUIL"
                        defaultValue="Ingrese"
                        {...(errors.CUIT && { error:true, helperText : errors.CUIT }) }
                    />
                </Grid>
                <Grid item sm={6} >
                    <TextField
                        required
                        value={Nombres} 
                        fullWidth={true}
                        onChange={(e) => setNombres(e.target.value)}
                        id="nombres"
                        label="Nombres"
                        {...(errors.Nombres && { error:true, helperText : errors.Nombres }) }
                    />
                </Grid>
                <Grid item sm={6} >
                    <TextField
                        required
                        fullWidth={true}
                        value={Apellido} 
                        onChange={(e) => setApellido(e.target.value)}
                        id="apellido"
                        label="Apellido"
                        {...(errors.Apellido && { error:true, helperText : errors.Apellido }) }
                    />
                </Grid>
            </Grid>
            <h3>Domicilio Cliente</h3>
            <Grid container rowSpacing={1} >
                <Grid item sm={3} >
                    <TextField
                        required
                        value={Calle} 
                        onChange={(e) => setCalle(e.target.value)}
                        id="calle"
                        label="Calle"
                        {...(errors.Calle && { error:true, helperText : errors.Calle }) }
                    />
                </Grid>
                <Grid item sm={3} >
                    <TextField
                        required
                        value={Nro} 
                        onChange={(e) => setNro(e.target.value)}
                        id="numeroCalle"
                        label="Numero"
                        {...(errors.Nro && { error:true, helperText : errors.Nro }) }
                    />
                </Grid>
                <Grid item sm={3} >
                    <TextField
                        required
                        value={Edif} 
                        onChange={(e) => setEdif(e.target.value)}
                        id="edifPisoDpto"
                        label="Edif-piso-dpto"
                        {...(errors.Edif && { error:true, helperText : errors.Edif }) }
                    />
                </Grid>
                <Grid item sm={3} >
                    <TextField
                        required
                        value={CP} 
                        onChange={(e) => setCp(e.target.value)}
                        id="cp"
                        label="CP"
                        {...(errors.CP && { error:true, helperText : errors.CP }) }
                    />
                </Grid>
                <Grid item sm={4}>
                <FormControl error={!!errors.Provincia} fullWidth={true} sx={{ minWidth: 120 }}>
                    <InputLabel id="partido-label">Provincia</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        fullWidth={true}
                        value={Provincia}
                        label="Provincia"
                        onChange={cambioProvincia}
                    >
                        {listadoProvincias ? listadoProvincias.map(
                            (item, index)=> (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                        ) : ''}
                    </Select>
                    <FormHelperText>{errors.Provincia? errors.Provincia : ''}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl error={!!errors.Partido} fullWidth={true} sx={{ minWidth: 120 }}>
                        <InputLabel id="partido-label">Partido</InputLabel>
                        <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={Partido}
                            label="Partido"
                            onChange={cambioPartido}
                        >
                            {listadoPartidos ? listadoPartidos.map(
                                (item, index )=> (<MenuItem key={index} value={item.Codigo}>{item.DESCRIPCION}</MenuItem>)
                            ) : ''}
                        </Select>
                        <FormHelperText>{errors.Partido? errors.Partido : ''}</FormHelperText>
                        
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                <FormControl error={!!errors.Localidad} required fullWidth={true} sx={{ minWidth: 120 }}>
                    <InputLabel id="partido-label">Localidad</InputLabel>
                    <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={Localidad}
                            label="Partido"
                            onChange={cambioLocalidad}
                        >
                            {listadoLocalidades ? listadoLocalidades.map(
                                (item, index) => (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                            ) : ''}
                        </Select>
                        <FormHelperText>{errors.Localidad? errors.Localidad : ''}</FormHelperText>
                </FormControl>
                </Grid>
            </Grid>
            <h3>Datos Complementarios</h3>
            <Grid container rowSpacing={1}>
                <Grid item sm={4} >
                    <TextField
                        required
                        fullWidth={true}
                        value={E_Mail} 
                        onChange={(e) => setE_Mail(e.target.value)}
                        id="email"
                        label="Email"
                        defaultValue="Hello World"
                        {...(errors.E_Mail && { error:true, helperText : errors.E_Mail }) }
                    />
                </Grid>
                <Grid item sm={4} >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="Fecha Nac"
                            inputFormat="dd/MM/yyyy"
                            value={Fecha_Nacim}
                            fullWidth={true}
                            onChange={(value) => { setFecha_Nacim(value)} }
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item sm={4}>
                <FormControl error={!!errors.Sexo} required fullWidth={true} sx={{ minWidth: 120 }}>
                    <InputLabel id="partido-label">Sexo</InputLabel>
                    <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={Sexo}
                            label="Partido"
                            onChange={(e) => setSexo(e.target.value)}
                        >
                            <MenuItem value='M'>M</MenuItem>
                            <MenuItem value='F'>F</MenuItem>
                        </Select>
                        <FormHelperText>{errors.Sexo? errors.Sexo : ''}</FormHelperText>
                </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Retiene IIBB</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={RetieneIIBB}
                            onChange={ (e) => setIIBB(e.target.value)}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="Si" />
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item sm={2}>
                <FormControl error={!!errors.SituacionIIBB} required fullWidth={true} sx={{ minWidth: 120 }}>
                    <InputLabel id="partido-label">Situacion IIBB</InputLabel>
                        <Select
                            disabled={RetieneIIBB == "false"}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={SituacionIIBB}
                            label="Situacion IIBB"
                            onChange={(e) => setSituacionIIBB(e.target.value)}
                        >
                            {listadoIIBB ? listadoIIBB.map(
                                (item, index)=> (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                            ) : ''}
                        </Select>
                        <FormHelperText>{errors.SituacionIIBB? errors.SituacionIIBB : ''}</FormHelperText>
                </FormControl>
                </Grid>
            </Grid>
            {!props.currentEditCliente.nuevo ? 
            <Grid conainer >
                <h3>Teléfonos</h3>
                <Button onClick={handleAddTelefono} variant="contained" type="submit">Agregar</Button>         
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Prefijo</TableCell>
                            <TableCell>Teléfono</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {telefonos.map((row,index) => (
                            <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.TipoTXT}
                                </TableCell>
                                <TableCell>{row.PrefijoClie}</TableCell>
                                <TableCell>{row.TelefonoClie}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Grid> : ''}
               

            <DialogActions>
               
                <Button variant="contained" color="secondary" onClick={props.onClose}>Cerrar</Button>
                <Button variant="contained" disabled={!getValidForm()} onClick={saveCliente}>Guardar</Button>
            </DialogActions>
        </DialogContent>
        </Dialog>
      )
}

export default EditarClienteModal;