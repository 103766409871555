import React, { useState } from 'react';
import { Typography, Grid, Slide} from '@mui/material';


import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as itemsSelector from '../../../state/orders/selectors';
// import * as itemsActions  from '../../../state/orders/actions';

import currencyFormat from '../../../utils/currencyFormat';
import gridStyle from '../gridStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

const Totales = () => {

    const payments = useSelector(itemsSelector.getPayments());
    const total = useSelector(itemsSelector.getTotal());
    const totalPayments = payments.reduce(
        (acumulator, currentPayment) => {
            return acumulator + currentPayment.Importe;
        },
        0
    )
    const autorizaFactura = totalPayments == total.importeTotal ? true : false;

    return (
    <div >
        <Grid container spacing={0} direction="row" style={gridStyle} >
            <Grid container spacing={0} direction="row">
                <Grid item xs={4} >
                    <Typography >Subtotal</Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography variant="h6" style={{fontWeight: 300, textAlign: 'right'}}>{currencyFormat(total.subTotal)}</Typography>
                </Grid>                
            </Grid>
            <Grid container spacing={0} direction="row" >
                <Grid item xs={4} >
                    <Typography >Descuentos</Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography variant="h6"  style={{fontWeight: 300, textAlign: 'right'}}>- {currencyFormat(total.descuentos)}</Typography>
                </Grid>                
            </Grid>                            
            <Grid container spacing={0} direction="row" >
                <Grid item xs={4} >
                    <Typography >Impuestos</Typography>
                </Grid>
                <Grid item xs={8} >
                        <Typography variant="h6" style={{ fontWeight: 300, textAlign: 'right' }}>{currencyFormat(total.impuestos)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} direction="row" >
                <Grid item xs={4} >
                    <Typography >Redondeo</Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography variant="h6" style={{ fontWeight: 300, textAlign: 'right' }}>{currencyFormat(total.redondeo)}</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={0} direction="row" >
                <Grid item xs={4} >
                    <Typography >Cuotas</Typography>
                </Grid>
                <Grid item xs={8} >
                    {total.cuotas > 1 ? <Typography variant="h6" style={{fontWeight: 300, textAlign: 'right'}}>{total.cuotas} cuotas de {currencyFormat(total.valorCuota)}</Typography> : null }
                </Grid>                
            </Grid>                                
            <Grid container spacing={0} direction="row" >
                <Grid item xs={4} >
                    <Typography variant="h5" >Total</Typography>
                </Grid>
                <Grid item xs={8} >
                    <Typography variant="h4" style={{textAlign: 'right', color : autorizaFactura ? 'green' : 'inherit' }}>{currencyFormat(total.importeTotal)}</Typography>
                </Grid>                
            </Grid>
        </Grid>
    </div>       
  );
}

export default Totales;