import React, { useEffect } from "react";
import { FormControl, Grid, InputLabel, Input, Box, Button, TextField, Select, MenuItem } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Api from "../../state/Api";
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import currencyFormat from "../../utils/currencyFormat";

const Home = () => {

    const [fechaDesde, setFechaDesde] = React.useState(moment().startOf('month').toDate());
    const [fechaHasta, setFechaHasta] = React.useState(moment().endOf('month').toDate());
    const [estados, setEstados ] = React.useState([]);
    const [nroPedido, setNroPedido ] = React.useState(null);
    const [nroDocumento, setNroDocumento ] = React.useState(null);
    const [selectedEstado, setSelectedEstado ] = React.useState(null);
    const [listadoEstados, setListadoEstados ] = React.useState([]); 
    const [listadoPedidos, setListadoPedidos ] = React.useState([])
    const [listadoPedidosRecientes, setListadoPedidosRecientes ] = React.useState([])
    const [openLoader, setOpenLoader] = React.useState(false);

    const buscarPorEstado = async () => {
        setOpenLoader(true)
        let fechaDesdeString = moment(fechaDesde).format('YYYYMMDD');
        let fechaHastaString = moment(fechaHasta).format('YYYYMMDD');
        let dataPedidos = await Api.req.get(`store/orders/status/${selectedEstado}?desde=${fechaDesdeString}&hasta=${fechaHastaString}`);
        setListadoPedidos(dataPedidos.data.data)
        setOpenLoader(false)
    }

    const buscarPorPedido = async () => {
        setOpenLoader(true)
        const searchParams = new URLSearchParams();
        if(nroDocumento){
            searchParams.append("nrodocu", nroDocumento);
        }
        if(nroPedido){
            searchParams.append("nropedido", nroPedido);
        }
        

        let dataPedidos = await Api.req.get(`store/orders?` + searchParams.toString());
        setListadoPedidos(dataPedidos.data.data)
        setOpenLoader(false)
    }

    const generateSubstring = (string) => {
        
        if(string && string.length > 20){
            return string.substring(0, 20) + '...';
        }else{
            return string
        }
    }

    const checkDisabledPedido = () => {
        if(nroDocumento || nroPedido){
            return false;
        }else{
            return true;
        }
    }

    const columns = [
        {
            field: 'test', key: 'action', headerName: 'Ver', flex : 1, width: 50, sortable: false, renderCell: (params) => {
                const { row } = params;

                const verPedido = (e) => {
                    let url = process.env.REACT_APP_ROUTE + '/#/pedido/' + row.NroPedido;
                    e.stopPropagation();
                    window.open(url, '_blank', 'noreferrer')
                }

                return (<><Button style={rowIcon} onClick={verPedido} ><OpenInNewIcon /></Button></>)
            }
        },
        {
            field: 'NroPedido', headerName: 'Nro pedido', flex: 1, width: 100, renderCell: (params) => (
                <div>
                    <div>{params.row.NroPedido}</div>
                    <div>{params.row.Estado}</div>
                </div>
            )
        },
        {
            field: 'Ape_Nom_Cliente', headerName: 'ApeNom', flex: 1, width: 180, renderCell: (params) => (
                <div>
                    <div>{params.row.Ape_Nom_Cliente}</div>
                    <div>{params.row.Documento}</div>
                    <div>{params.row.Loca_Entrega}</div>
                </div>
            )
        },
        {
            field: 'Importe', headerName: 'Importe', flex: 1 , minWidth: 180, renderCell: (params) => (
            <div>
                <div>{params.row.Importe}</div>
                <div>{generateSubstring(params.row.tarjetaDescripcion)}</div>
            </div>
            )
        },
        { field: 'Fecha', headerName: 'Fecha', flex: 1, width: 100 , valueGetter: (params) => moment(params.row.Fecha).format('DD/MM/YYYY') },

    ];

    const rowIcon = {
        minWidth: '20px'
    }

    useEffect( () => {
        const getEstados = async () => {
            let dataEstados = await Api.req.get(`store/orders/status`);
            setListadoEstados(dataEstados.data.data)
  
        }

        const getPedidosRecientes = async () => {
            let pedidosRecientes = await Api.req.get(`store/orders/recent`);
            console.log(pedidosRecientes)
            setListadoPedidosRecientes(pedidosRecientes.data.data)
        }

        getPedidosRecientes();
        getEstados();

    } , [ ])

    return (
    <>
            <Box sx={{ padding: '10px'}}>

                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
                            open={openLoader}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        <h3>Pedidos Recientes</h3>
                        <DataGrid
                            rowHeight={75}
                            className="stripped"
                            style={{ flex: 1, height: '83vh', overflow: 'auto' }}
                            rows={listadoPedidosRecientes}
                            getRowId={(row) => row.NroPedido}
                            columns={columns}
                            hideFooterPagination
                            disableSelectionOnClick
                        >
                        </DataGrid>
                    </Grid>
                    <Grid item xs={6}>
                        <h3>Control Pedidos</h3>
                        <Grid container>
                            <Grid item xs={4}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="component-simple">Nro Pedido</InputLabel>
                                    <Input id="component-simple" onChange={(e) => setNroPedido(e.target.value)} value={nroPedido} defaultValue="" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="component-simple">Nro Documento</InputLabel>
                                    <Input id="component-simple" onChange={(e) => setNroDocumento(e.target.value)} value={nroDocumento} defaultValue="" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <Button size="large" disabled={checkDisabledPedido() || openLoader} onClick={buscarPorPedido} startIcon={<SearchIcon />} variant="contained" > Buscar </Button>
                            </Grid>
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container spacing={2} sx={{ marginTop: '20px' }} >
                                <Grid item xs={3}>
                                    <DesktopDatePicker
                                        label="Fecha"
                                        inputFormat="dd/MM/yyyy"
                                        value={fechaDesde}
                                        fullWidth={true}
                                        onChange={(value) => { setFechaDesde(value) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <DesktopDatePicker
                                        label="Hasta"
                                        inputFormat="dd/MM/yyyy"
                                        value={fechaHasta}
                                        fullWidth={true}
                                        onChange={(value) => { setFechaHasta(value) }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                    <FormControl sx={{ width: '150px' }}>
                                        <InputLabel id="partido-label">Estado</InputLabel>
                                        <Select
                                            labelId="estado"
                                            id="estado"
                                            fullWidth={true}
                                            value={selectedEstado}
                                            label="Provincia"
                                            onChange={(e) => { setSelectedEstado(e.target.value) }}
                                        >
                                            {listadoEstados ? listadoEstados.map(
                                                (item, index) => (<MenuItem key={index} value={item.codigo}>{item.descripcion}</MenuItem>)
                                            ) : ''}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button size="large" disabled={!selectedEstado || openLoader} onClick={buscarPorEstado} startIcon={<SearchIcon />} variant="contained" > Buscar </Button>
                                </Grid>
                            </Grid>

                            <br />
                        </LocalizationProvider>
                        <Grid item xs={12}>
                            
                            <DataGrid
                                rowHeight={75}
                                className="stripped"
                                style={{ flex: 1, height: '65vh', overflow: 'auto' }}
                                rows={listadoPedidos}
                                getRowId={(row) => row.NroPedido}
                                columns={columns}
                                hideFooterPagination
                                disableSelectionOnClick
                            >
                            </DataGrid>
                        </Grid> 
                    </Grid>
                </Grid>
        </Box>
    </>
    )
}

export default Home;