import React, { useState, useEffect } from 'react';
import { Dialog , DialogTitle, DialogContent, DialogContentText } from '@mui/material';


const NuevoPedidoModal = (props) => {
    const handleClose = () => {
        props.onClose()
    }

    return(
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
        >
        <DialogTitle>Nuevo Pedido Modal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText>
        </DialogContent>
        </Dialog>
      )
}

export default NuevoPedidoModal;