import React, { useState } from 'react';
import { Typography, Chip, MenuItem, FormControl, Button, Select, Grid, Snackbar, Alert} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import * as itemsSelector from '../../../state/orders/selectors';
import Api from '../../../state/Api';
import { useDispatch, useSelector } from 'react-redux';
import * as ordersActions from '../../../state/orders/actions';
import ModalFacturar from './ModalFacturar';
import { useNavigate } from "react-router-dom";

const Acciones = () => {
  const dispatch = useDispatch();
  const [medio, setMedio] = React.useState(10);
  const [codOperador, setOperador] = React.useState(1);
  const [openModalFacturar, setOpenModalFacturar] = React.useState(false)
  const isNewPedido = useSelector(itemsSelector.getNewPedido());
  const isAfacturar = useSelector(itemsSelector.getAfacturar());
  const nroFactura = useSelector(itemsSelector.getNroFactura());
  const facturado = useSelector(itemsSelector.getPedidoFacturado());
  const payments = useSelector(itemsSelector.getPayments());
  const total = useSelector(itemsSelector.getTotal());
  const itemsCount = useSelector(itemsSelector.getItemsCount()); 

  const totalPayments = payments.reduce( 
    (acumulator , currentPayment) => { 
      return acumulator + currentPayment.Importe;
    },
    0 
  )
  const autorizaFactura = (totalPayments == total.importeTotal) && (itemsCount > 0 )  ? true : false;

  const [alert, setAlert] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });

  const vertical = 'bottom';
  const horizontal = 'center';

  const datosPedido = useSelector(itemsSelector.getDatosPedido());
  const datosCliente = useSelector(itemsSelector.getDatosCliente());
  const fullStoreAddress = useSelector(itemsSelector.getFullStoreAddress());

  const items = useSelector(itemsSelector.fetchAllItems());
  const nroPedido = useSelector(itemsSelector.getNroPedido());  
  const navigate = useNavigate();

  const orderPayments = (payments) => {
    let paymentsArray = [...payments];
    let efectivoIndex = paymentsArray.findIndex((payment) => payment.formapago == 'EFECTIVO')
    if (efectivoIndex != -1) {
      let spliced = paymentsArray.splice(efectivoIndex, 1);
      paymentsArray.push(spliced[0])
    }

    return paymentsArray;
  }

  const orderedPayments = orderPayments(payments);

  const datosStore = {
        Ape_Entrega: datosCliente.Apellido,
        Nom_Entrega: datosCliente.Nombres,
        Calle_Entrega: fullStoreAddress.Calle,
        Nro_Entrega: fullStoreAddress.Nro,
        Edif_Entrega: '',
        CP_Entrega: fullStoreAddress.CP,
        Loca_Entrega: '',
        Parti_Entrega: '',
        PartiEntregaTXT: '',
        Provincia: fullStoreAddress.Provincia,
        ProvinciaEntregaTXT: fullStoreAddress.Provincia,
  }


  const datosPedidoAddress = {    
        Ape_Entrega: datosPedido.Ape_Entrega,
        Nom_Entrega: datosPedido.Nom_Entrega,
        Calle_Entrega: datosPedido.Calle_Entrega,
        Nro_Entrega: datosPedido.Nro_Entrega,
        Edif_Entrega: datosPedido.Edif_Entrega,
        CP_Entrega: datosPedido.CP_Entrega,
        Loca_Entrega: datosPedido.Loca_Entrega,
        Parti_Entrega: datosPedido.idParti_Entrega,
        PartiEntregaTXT: datosPedido.Parti_Entrega,
        Provincia: datosPedido.idProvi_Entrega,
        ProvinciaEntregaTXT: datosPedido.Provi_Entrega,
  }

  const datosEntrega =  datosPedido.Retira_Local ? datosStore : datosPedidoAddress;

  const facturarJson = {
    operador: datosPedido.OperadorCod,
    medio: datosPedido.codMedio,
    redondeo :  total.redondeo,
    descuentos :  total.descuentos,  
    impuestos : total.impuestos,
    store: fullStoreAddress ,
    cliente: {
      Tipo_Docu: datosCliente.Tipo_Docu,
      Nro_Docu: datosCliente.Nro_Docu,
      Apellido: datosCliente.Apellido,
      Nombres: datosCliente.Nombres,
      Calle: datosCliente.Calle,
      Nro: datosCliente.Nro,
      Edif: datosCliente.Edif,
      CP: datosCliente.CP,
      Localidad: datosCliente.Localidad,
      Partido: datosCliente.Partido,
      Provincia: datosCliente.Provincia,
      SituacionIIBB: datosCliente.SituacionIIBB,
      RetieneIIBB: datosCliente.RetieneIIBB,
      Sexo: datosCliente.Sexo,
      E_Mail: datosCliente.E_Mail,
      Fraudulento: datosCliente.Fraudulento,
      Mayorista: datosCliente.Mayorista
    },
    entrega: datosEntrega ,
    formaPago: datosPedido.currentFormaPago,
    formaPagoTXT: datosPedido.currentFormaPagoString,
    condicionesIva : datosPedido.condicionesIva,
    IVA: datosPedido.condicionIva,//FALTA
    Cuotas: datosPedido.Cuotas,
    Importe1: datosPedido.Importe1,
    Importe2: datosPedido.Importe2,
    Importe3: datosPedido.Importe3,
    Retira_Local: datosPedido.Retira_Local,
    Envio_Preferencial: datosPedido.Envio_Preferencial,
    cobros: payments,
    items: items,
    Tipo_Factura: datosPedido.Tipo_Factura,
    IIBB: false
  }
  
  
  const sendFacturar = async () => {
    
    setOpenModalFacturar(true)
    //
  }

  const handleClose = () => {
    setAlert({ ...alert, open: false });
  };  

  const handleCloseModal = () => {
    setOpenModalFacturar(false);
  }

  const handleChangeOperador = (event) => {
    setOperador(event.target.value);
  };
  const handleChange = (event) => {
    setMedio(event.target.value);
  };

  const handleCerrar = () => {
    navigate('/')
    dispatch(ordersActions.setNroPedido(null))
  }

  const datosValidosEnvio = () => {
    var datos = false;
    var esCapital = datosPedido.idProvi_Entrega == 'C' ? true : false;
    var tieneDatosPartidos = false;
    var tieneDatosDireccion = false;

    
    if (esCapital && datosPedido.Loca_Entrega && datosPedido.Provi_Entrega) {
      tieneDatosPartidos =  true;
    }

    if (!esCapital && datosPedido.Loca_Entrega && datosPedido.Provi_Entrega && datosPedido.Parti_Entrega) {
      tieneDatosPartidos = true;
    }
   
    if (datosPedido.Ape_Entrega && 
       datosPedido.Nom_Entrega && 
       datosPedido.Calle_Entrega && 
       datosPedido.Nro_Entrega && 
       datosPedido.Edif_Entrega && 
       datosPedido.CP_Entrega 
       ){
        tieneDatosDireccion = true;
    }

    /*
    console.log(tieneDatosPartidos, '<< tieneDatosPartidos x')
    console.log(tieneDatosDireccion, '<< tieneDatosDireccion')
    console.log(esCapital, '<< esCapital')*/

    if (datosPedido.Envio_Preferencial && tieneDatosDireccion && tieneDatosPartidos){
      return true
    }

    if (datosPedido.Retira_Local){
      return true
    } 

    return false;
  }

  const validFacturar = () => {
    var requisitosPedido = false

    if ((isNewPedido && facturado == false) || isAfacturar){
      requisitosPedido = true
    }

    if (requisitosPedido && datosValidosEnvio()){
      return true
    }
    return false

  }

  // 'linear-gradient(to bottom, #007bff, #4466f2'
  // 'linear-gradient(to bottom, #ccc, #ededed'
  return (
    <div>
      <ModalFacturar nroPedido={nroPedido} onClose={handleCloseModal}  facturarJson={facturarJson} open={openModalFacturar} />
      <Grid container spacing={2} >
          <Grid item xs={12} style={{textAlign: 'right'}}>
              <Button
                size="small"
                variant="contained"
                color="neutral"
                onClick={handleCerrar}
                sx={{ borderRadius: 8, width: '150px', height: '40px', textAlign: 'right', backgroundColor: 'green' }}
                style={{ display: (isNewPedido && facturado) || (isAfacturar && facturado ) ? 'inline-flex' : 'none' }}
              >
                CERRAR
              </Button>
              <Button 
                style={{ display: validFacturar() ? 'inline-flex' : 'none' }}
                size="small" 
                variant="contained" 
                color="neutral"
                onClick={sendFacturar}
                disabled={!autorizaFactura} 
                endIcon={<ChevronRight />}
                sx={{borderRadius: 8, width: '150px', height: '40px', textAlign: 'right', backgroundColor : 'green'}}
                >
                  FACTURAR 
                </Button>
                
          </Grid>                                        
          {/* <Grid item xs={4} >
              <Button variant="contained" disabled  fullWidth>LIMPIAR</Button>
          </Grid>                                 
          <Grid item xs={4} >
              <Button variant="contained" color="info"  fullWidth>CERRAR</Button>
          </Grid>                                                  */}
      </Grid>
      <Snackbar
        severity="success"
        anchorOrigin={{ vertical, horizontal }}
        open={false}
        onClose={handleClose}
        message="I love snacks"
        key={vertical + horizontal}       
      >
      <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
        This is a success message!
      </Alert>
      </Snackbar>
    </div>
  );
}

export default Acciones;