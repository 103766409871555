import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as itemsSelector from '../../../state/orders/selectors';
import InputAdornment from '@mui/material/InputAdornment';
import gridStyle from '../gridStyle';
import * as ordersActions from '../../../state/orders/actions';

const Importes = () => {


    const orderPayments = (payments) => {
        let paymentsArray = [...payments];
        let efectivoIndex = paymentsArray.findIndex( (payment) => payment.formapago ==  'EFECTIVO' )
        if(efectivoIndex != -1){
            let spliced = paymentsArray.splice(efectivoIndex, 1);
            paymentsArray.push(spliced[0])
        }
    
        return paymentsArray;
    }
    const dispatch = useDispatch();
    const payments = useSelector(itemsSelector.getPayments());
    const [label1, setLabel1] = React.useState('')
    const [label2, setLabel2] = React.useState('')
    const [label3, setLabel3] = React.useState('')
    const [colsWidth, setColsWidth] = React.useState(6)
    const tiene2payments = payments.length >= 2 ? true : false;
    const orderedPayments = orderPayments(payments);
    const facturado = useSelector(itemsSelector.getPedidoFacturado());
    const Importe1 = useSelector(itemsSelector.getImporte(1));
    const Importe2 = useSelector(itemsSelector.getImporte(2));
    const Importe3 = useSelector(itemsSelector.getImporte(3));

    const changeImporte = (numImporte, value) => {
        dispatch(ordersActions.setImporte(numImporte, value))
    }

    const toDecimal = (num) => num.toFixed(2)

    useEffect(() => {
        
        if (orderedPayments.length == 1){
            //setImporte1(toDecimal(orderedPayments[0].Importe))
            dispatch(ordersActions.setImporte(1,toDecimal(orderedPayments[0].Importe)))
            setLabel1(orderedPayments[0].formapago)
            dispatch(ordersActions.setImporte(2, 0))
            setLabel2('')
            dispatch(ordersActions.setImporte(3, 0))
            setLabel3('')
        }

        if (orderedPayments.length == 2) {
            dispatch(ordersActions.setImporte(1, toDecimal(orderedPayments[0].Importe)))
            setLabel1(orderedPayments[0].formapago)
            dispatch(ordersActions.setImporte(2, toDecimal(orderedPayments[1].Importe) ))
            setLabel2(orderedPayments[1].formapago)
            dispatch(ordersActions.setImporte(3, 0))
            setLabel3('')
            setColsWidth(6)
        }

        if (orderedPayments.length == 3) {
            dispatch(ordersActions.setImporte(1, toDecimal(orderedPayments[0].Importe) ))
            setLabel1(orderedPayments[0].formapago)
            dispatch(ordersActions.setImporte(2, toDecimal(orderedPayments[1].Importe)) )
            setLabel2(orderedPayments[1].formapago)
            dispatch(ordersActions.setImporte(3, toDecimal(orderedPayments[2].Importe)) )
            setLabel3(orderedPayments[2].formapago)
            setColsWidth(4)
        }

    },[payments] )

    return(
        <Grid container spacing={0} direction="row" style={gridStyle}>
            <Grid item xs={colsWidth} >
                <TextField
                    value={Importe1}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    onChange={(e) =>changeImporte(1, e.target.value)}
                    readOnly={!tiene2payments || facturado}
                    inputProps={{ style: { textAlign: 'right' }, readOnly: !tiene2payments || facturado }}
                    style={{ textAlign: 'right' }}
                    id="Importe1"
                    label={label1}
                />
            </Grid>
            <Grid item xs={colsWidth} >
                <TextField
                    value={Importe2}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    //onChange={(e) => setImporte2(makeFloat(e.target.value))}
                    onChange={(e) => changeImporte(2, e.target.value)}
                    inputProps={{ style: { textAlign: 'right' }, readOnly: !tiene2payments || facturado }} 
                    style={{textAlign : 'right'}}
                    id="Importe2"
                    label={label2}      
                />
            </Grid>
            { payments.length == 3 ? 
                <Grid item xs={colsWidth} >
                <TextField
                    value={Importe3}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    onChange={(e) => changeImporte(3, e.target.value)}
                    inputProps={{ style: { textAlign: 'right' }, readOnly: !tiene2payments || facturado }} 
                    id="Importe3"
                    label={label3}
                />
            </Grid> : '' }

        </Grid>
    )
}

export default Importes;