import Api from '../Api';
import * as storeActions from '../store/actions';

import { ADD, ADD_ITEM_SUCCESS, FETCH,
    FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE, OPENDRAWER, CLOSEDRAWER,
    UPDATE_ITEM_SUCCESS, UPDATE_ITEM_FAILURE, UPDATE_ITEM_REQUEST, UPDATE_CUOTAS_REQUEST,
    UPDATE_CUOTAS_SUCCESS, UPDATE_CUOTAS_FAILURE, SET_NRO_PEDIDO, FETCH_DATOS_CLIENTE_REQUEST,
    FETCH_DATOS_CLIENTE_SUCCESS, FETCH_DATOS_CLIENTE_FAILURE, FETCH_DATOS_PEDIDO_REQUEST, FETCH_DATOS_PEDIDO_SUCCESS,
    FETCH_DATOS_PEDIDO_FAILURE, SET_NEW_PEDIDO, SET_CURRENT_LISTA, RESET_FILTROS, INIT_PEDIDO, DELETE_NEW_PEDIDO,
    FETCH_PAYMENTS_PEDIDO_REQUEST, FETCH_PAYMENTS_PEDIDO_SUCCESS, FETCH_PAYMENTS_PEDIDO_FAILURE, SET_CURRENT_FORMAPAGO, 
    SET_CURRENT_LISTA_CUOTAS, SET_TIPOS_FACTURA, SET_TIPO_FACTURA , SET_CONDICIONES_IVA, SET_CONDICION_IVA, SET_TIPO_ENVIO,
    FETCH_STORE_ADDRESS_REQUEST, FETCH_STORE_ADDRESS_SUCCESS, FETCH_STORE_ADDRESS_FAILURE, SET_ADDRESS_DATA, SET_OPERADOR, SET_MEDIO,
    DELETE_ITEM_REQUEST, DELETE_ITEM_SUCCESS, DELETE_ITEM_FAILURE, SET_NRO_FACTURA, SET_PEDIDO_FACTURADO, SET_IMPORTE } from './constants';

export const add = skus => (dispatch) => {
  // console.log(users);
  // if (typeof users === 'number') dispatch({ type: USER_QUEUE_ADD, id: skus });
  // else dispatch({ type: ADD, data: skus });
};

export const setCurrentLista = (nombreLista) => async (dispatch) => {
    dispatch({ type: SET_CURRENT_LISTA, payload:  nombreLista  });
}

export const setPedidoFacturado = () => async (dispatch) => {
    dispatch({ type: SET_PEDIDO_FACTURADO });
}

export const setNroFactura = (nroFactura) => async (dispatch) => {
    dispatch({ type: SET_NRO_FACTURA, payload: nroFactura });
}

export const getStoreAddress = () =>  async (dispatch) => {
 dispatch({ type: FETCH_STORE_ADDRESS_REQUEST });
    try {
      
        const  storeData  = await Api.req.get(`store`);
        const parsedData = storeData.data.data[0];
      
        if (parsedData) {
            dispatch({ type: FETCH_STORE_ADDRESS_SUCCESS, payload: parsedData })
        }
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar la dirección de la tienda : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_STORE_ADDRESS_FAILURE, error: (error || err) });
    }
}

export const setImporte = (nroImporte, valor) => async (dispatch) => {
    dispatch({ type: SET_IMPORTE, payload: {nroImporte : nroImporte, valor: valor} })
}


export const setAddressData = (data) => async(dispatch) => {
    dispatch({ type: SET_ADDRESS_DATA, payload: data } )
}

export const setOperador = (data) => async (dispatch) => {
    dispatch({ type: SET_OPERADOR, payload: data })
}

export const setMedio = (data) => async (dispatch) => {
    dispatch({ type: SET_MEDIO, payload: data })
}

export const setTipoEnvio = (tipoEnvio) => async (dispatch) => {
    dispatch({ type: SET_TIPO_ENVIO, payload: tipoEnvio  });
}

export const setCurrentCuotas = (cuotas) => async (dispatch) => {
    dispatch({ type: SET_CURRENT_LISTA_CUOTAS, payload: cuotas });
}

export const setCurrentFormaPago = (formaPago,formaPagoString) => async (dispatch) => {
    dispatch({ type: SET_CURRENT_FORMAPAGO, payload: { formaPago : formaPago, formaPagoString : formaPagoString }  });
}

export const setNroPedido = (nroPedido, cliente) => async (dispatch) => {
    if (nroPedido == null) {
        dispatch({ type: DELETE_NEW_PEDIDO })
    } else {
        dispatch({ type: SET_NRO_PEDIDO, payload: { nroPedido: nroPedido, cliente: cliente } });
    }
  
}

export const setNewPedido = (nroPedido, cliente) => async (dispatch) => {
        dispatch({ type: SET_NEW_PEDIDO, payload: { nroPedido: nroPedido, cliente: cliente } });
        dispatch({ type: UPDATE_CUOTAS_SUCCESS, payload: 1 })
}

export const initPedido = (nroPedido, cliente) => async (dispatch) => {
    dispatch({ type: INIT_PEDIDO })
}

export const fetchDatosCliente = (nroPedido) => async (dispatch) => {
    dispatch({ type: FETCH_DATOS_CLIENTE_REQUEST });
    try {
        
        const { data } = await Api.req.get(`orders/order/${nroPedido}/customer`);
        const parsedData = data.items[0];

        if (parsedData) {
            dispatch({ type: FETCH_DATOS_CLIENTE_SUCCESS, payload: parsedData })
        }
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar los datos del cliente : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_DATOS_CLIENTE_FAILURE, error: (error || err) });
    } 
}

export const setDatosCliente = (datosCliente) => async (dispatch) => {

    dispatch({ type: FETCH_DATOS_CLIENTE_SUCCESS, payload : datosCliente })
}

export const fetchConfiguracionPedido = (nroPedido) => async (dispatch) => {
    dispatch({ type: FETCH_DATOS_PEDIDO_REQUEST });
   
    try {
      
        const { data } = await Api.req.get(`orders/order/${nroPedido}`);
        const parsedData = data.items[0];
        if (parsedData) {
            dispatch({ type: FETCH_DATOS_PEDIDO_SUCCESS, payload: parsedData })
            
        }
        dispatch(getPayments(nroPedido))
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar la configuracion del pedido : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_DATOS_PEDIDO_FAILURE, error: (error || err) });
    }
}

export const getPayments = (nroPedido) => async (dispatch) => {
    
    try {
        const { data } = await Api.req.get(`/orders/order/${nroPedido}/payments`);
        const parsedData = data.cobros;

        dispatch({ type: FETCH_PAYMENTS_PEDIDO_SUCCESS, payload: parsedData })
        dispatch(getStoreAddress())
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar los pagos del pedido : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_PAYMENTS_PEDIDO_FAILURE, error: (error || err) });
    }
}

export const setTipoFactura = (tipo) => async (dispatch) => {
    dispatch({ type: SET_TIPO_FACTURA, payload: tipo })

}

export const setCondicionIva = (codigo) => async (dispatch) => {
    dispatch({ type: SET_CONDICION_IVA, payload: codigo })

}

export const getTiposFactura = (nroPedido) => async (dispatch) => {
    try {
        const { data } = await Api.req.get(`/store/tiposFactura`);
        const parsedData = data.data;
        dispatch({ type: SET_TIPOS_FACTURA, payload: parsedData })
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar los tipos de factura : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_PAYMENTS_PEDIDO_FAILURE, error: (error || err) });
    }
}



export const getCondicionesIva = (nroPedido) => async (dispatch) => {
    try {
        const { data } = await Api.req.get(`/store/condIVA`);
        const parsedData = data.data;
        dispatch({ type: SET_CONDICIONES_IVA, payload: parsedData })
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar las condiciones de IVA : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_PAYMENTS_PEDIDO_FAILURE, error: (error || err) });
    }
}

export const fetchAll = (nroPedido) => async (dispatch) => {
    dispatch({ type: FETCH });
    try {
        const { data } = await Api.req.get(`/orders/order/${nroPedido}/items`);
        const parsedData = data.items;

        dispatch({ type: FETCH_ITEMS_SUCCESS, payload: parsedData })
        if(parsedData.length == 0 ){
            dispatch({ type: RESET_FILTROS })   
        }

    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al buscar los items del pedido : ' + err, 'error'))
        console.log(err)
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_ITEMS_FAILURE, error: (error || err) });
    }

};

export const addToCart = (item) => async (dispatch) => {
    dispatch({ type: ADD });
    try {
        const { data } = await Api.req.post(`/orders/order/${item.nropedido}/items`, {
            item
        });
        const parsedData = data.item;

        if(parsedData){
            
            dispatch({ type: ADD_ITEM_SUCCESS, payload: parsedData });
            dispatch({ type: CLOSEDRAWER });
        }
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al agregar el item al carrito : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_ITEMS_FAILURE, error: (error || err) });
    }

};

export const updateCuotas = (cuotas, nroPedido) => async (dispatch) => {
        dispatch({ type: UPDATE_CUOTAS_REQUEST });

        try {
            const { data } = await Api.req.put(`/orders/order/${nroPedido}/installments/`, {
                cuotas : cuotas
            });
            const parsedData = data;
            if(parsedData){
                dispatch({ type: UPDATE_CUOTAS_SUCCESS, payload: cuotas })
                dispatch(fetchAll(nroPedido))
            }
        }
        catch (err) {
            dispatch(storeActions.addToast('Ha habido un error al actualizar las cuotas : ' + err, 'error'))
            let error = err.message;
            if (err.response) {
                error = err.response.data.error;
            } else if (err.request) {
                error = err.request;
            }
            dispatch({ type: UPDATE_CUOTAS_FAILURE, error: (error || err) });
        }
}

export const deleteItem = (id, nroPedido) => async (dispatch) => {
    dispatch({ type: DELETE_ITEM_REQUEST });

    try{
        await Api.req.delete(`orders/order/${nroPedido}/items/${id}`)
        dispatch({ type : DELETE_ITEM_SUCCESS })
        dispatch(fetchAll(nroPedido))
    }
    catch(err){
        dispatch(storeActions.addToast('Ha habido un error al borrar el item del pedido : ' + err, 'error'))
        dispatch({ type: DELETE_ITEM_FAILURE })
    }
   
}

export const updateItem = (item) => async (dispatch) => {
    dispatch({ type: UPDATE_ITEM_REQUEST });
    try {
        const { data } = await Api.req.put(`/orders/order/${item.NroPedido}/items/${item.Orden}`, {
            item
        });
        const parsedData = data.items;

        if(parsedData){
            dispatch({ type: UPDATE_ITEM_SUCCESS, payload: parsedData })
        }
    }
    catch (err) {
        dispatch(storeActions.addToast('Ha habido un error al actualizar el item : ' + err, 'error'))
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: UPDATE_ITEM_FAILURE, error: (error || err) });
    }

};

export const updateCart = (cart) => async (dispatch) => {
    dispatch({ type: FETCH });
    try {
        const { data } = await Api.req.put(`/orders/order/117192/items`, {
            cart
        });
        const parsedData = data.items;

        if(parsedData){
            dispatch({ type: FETCH_ITEMS_SUCCESS, payload: parsedData })
        }
    }
    catch (err) {
        console.log(err)
        let error = err.message;
        if (err.response) {
            error = err.response.data.error;
        } else if (err.request) {
            error = err.request;
        }
        dispatch({ type: FETCH_ITEMS_FAILURE, error: (error || err) });
    }

};

export const openSKUDrawer = (item) => async (dispatch) => {
    try {
        dispatch({ type: OPENDRAWER });
    }
    catch (err) {
        console.log(err)
    }    
}

export const closeSKUDrawer = (item) => async (dispatch) => {
    try {
        dispatch({ type: CLOSEDRAWER });
    }
    catch (err) {
        console.log(err)
    }    
}