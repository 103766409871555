import React, { useEffect, useState } from 'react';
import { TextField, Grid, Select, MenuItem} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/PermIdentity';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TruckIcon from '@mui/icons-material/LocalShipping';
import HomeIcon from '@mui/icons-material/Storefront';
import LocationIcon from '@mui/icons-material/FmdGood';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import * as itemsSelector from '../../../state/orders/selectors';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FaceIcon from '@mui/icons-material/Face';
import EditIcon from '@mui/icons-material/Edit';
import EditarClienteModal from '../../Cliente/Resultados/EditarClienteModal';
import gridStyle from '../gridStyle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ClienteHeader = () => {
    const dispatch = useDispatch();
    const datosCliente = useSelector(itemsSelector.getDatosCliente());
    const [ datosEdit , setDatosEdit ] = React.useState( Object.assign(datosCliente) )
    const [view, setView] = React.useState('retiro');
    const altoRiesgoTitle = `Se aplicarán percepciones según padrón de Alto Riesgo Fiscal: Percepción: ${datosCliente.PorcentajeRetencion}%`;
    const [tieneAlerta, setTieneAlerta] = React.useState(false);
    const [openModal, setOpenModal ]  = React.useState(false);
    const [editarClienteOpen, setEditarClienteOpen] = useState(false);
    const isFacturado = useSelector(itemsSelector.getPedidoFacturado());

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (event, nextView) => {
      setView(nextView);
    };

    const handleOpenEditar = () => {
        setEditarClienteOpen(true)
    }

    const closeEditarCliente = () => {
        setDatosEdit(Object.assign({}, datosCliente))
        setEditarClienteOpen(false)
    }


    useEffect( () => { 
        setTieneAlerta(datosCliente.AltoRiesgo || datosCliente.Fraudulento)
    }, [datosCliente] )


  // 'linear-gradient(to bottom, #007bff, #4466f2'
  return (
    <>
        <Modal
            open={tieneAlerta}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
                  ATENCIÓN

                  <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        
                          <TableBody>
                                  { !datosCliente.Fraudulento ?  <TableRow>
                                      <TableCell component="td" scope="row">
                                        <Chip size="lg" icon={<FaceIcon />} variant="solid" color="error" label="Fraudulento" />
                                      
                                      </TableCell>
                                      <TableCell >El cliente está indicado como Fraudulento.</TableCell>
                                  </TableRow> : '' }
                                  { !datosCliente.AltoRiesgo ? <TableRow>
                                      <TableCell component="td" scope="row">
                                      <Chip size="lg" icon={<FaceIcon />} style={{ backgroundColor : 'orange'}} variant="solid" color="error" label="Percepciones" />
                                      </TableCell>
                                  <TableCell >Se aplicarán percepciones según padrón de Alto Riesgo Fiscal: Percepción: {datosCliente.PorcentajeRetencion}%</TableCell>
                                  </TableRow> : '' }
                          </TableBody>
                      </Table>
                  </TableContainer>
                  <Button style={{ marginTop: '20px', float: 'right' }} variant="contained" onClick={() => { setTieneAlerta(false) } }>Aceptar</Button>
            </Box>
        </Modal>
          <Grid container spacing={0} style={gridStyle} >
            <Grid container spacing={1}  direction="row" >
            <Grid item xs={12} >
                <Grid container spacing={2} direction="row" >
                    <Grid item xs={7} >
                        <TextField
                            id="input-with-icon-textfield"
                            label="Apellido y Nombres"
                            value={datosCliente.Nombres + ' ' + datosCliente.Apellido}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                    <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            readOnly
                            size="small"
                        />
                    </Grid>  
                    <Grid item xs={3} >
                        <Grid container spacing={1} direction="row" >
                            <Grid item xs={4} >
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={1}
                                    label="Operador"
                                    // onChange={handleChangeOperador}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    disabled={isFacturado}
                                    style={{backgroundColor: 'white'}}
                                >
                                    <MenuItem value={1}>DNI</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={8} >
                                      <TextField InputLabelProps={{ shrink: !!datosCliente.Nro_Docu }} label="Nro. Documento" variant="outlined" value={datosCliente.Nro_Docu} color="primary" readOnly="true"  fullWidth size="small"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={1}>
                        <EditarClienteModal enPedido={true} currentEditCliente={datosEdit} onClose={closeEditarCliente} open={editarClienteOpen}></EditarClienteModal>
                        <Button disabled={isFacturado} variant='outlined' onClick={handleOpenEditar} ><EditIcon/></Button>
                    </Grid>
                    <Grid item xs={2} >
                        {datosCliente.Fraudulento ? <Chip size="lg" variant="solid" color="error" label="Fraudulento"/> : '' }  
                        {datosCliente.AltoRiesgo ? <Tooltip title={altoRiesgoTitle} arrow><Button>⚠</Button></Tooltip> : '' }  
                    </Grid>
                </Grid>                                   
            </Grid>                    
            </Grid>
        </Grid>  
    </>              
    );
}

export default ClienteHeader;