import produce from 'immer';
import { ADD,ADDPRICELISTS, FETCHING_SKUS, RECEIVED_ALL_SKUS, REQUEST_ALL_SKUS, FETCH_SKUS_SUCCESS, FETCH_SKUS_FAILURE,ADDINSTALLMENTS } from './constants';

export const initialState = {
  data: {},
  config : { pausa : true },
  loading: { isLoading : false  },
  skus : [],
  skusFetching: false,
};

const reducer = (state = initialState, action) => { return produce(state, (draftState) => {
  switch(action.type) {

    case FETCH_SKUS_SUCCESS:
      draftState.skus = [ ...action.payload ];
      draftState.loading.isLoading = false;
      break;

    case FETCH_SKUS_FAILURE:
        draftState.loading.isLoading = false;
        break;

    case REQUEST_ALL_SKUS: 
      draftState.isFetching = true;
      draftState.loading.isLoading = true
      break;

    case FETCHING_SKUS:
      draftState.skusFetching = action.fetching;
      break;

    case RECEIVED_ALL_SKUS:
      draftState.loading.isLoading = true
      draftState.skus.isFetching = false;
      draftState.skus.items = [...action.payload];
      break;     

    case ADDPRICELISTS:
      const index = draftState.skus.findIndex(sku => (
        action.payload[0].PLU === sku.PLU
      ));
      
      draftState.skus[index].pricelists = action.payload;
      draftState.loading.isLoading = false;
      break;

    case ADDINSTALLMENTS:
      const indexI = draftState.skus.findIndex(sku => (
        action.payload[0].PLU === sku.PLU
      ));

      const indexL = draftState.skus[indexI].pricelists.findIndex(list => (
        action.payload[0].nombreGrupo === list.nombreGrupo
      ));
      
      draftState.skus[indexI].pricelists[indexL].installments = action.payload
      draftState.loading.isLoading = false;
      break;

    default:

  }
  })
};

export default reducer;
