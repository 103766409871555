export const ADD = 'store/ADD';
export const FETCH = 'store/GET';
export const FETCH_DOCUMENTOS_SUCCESS = 'store/FETCH_DOCUMENTOS_SUCCESS';
export const FETCH_FORMASDEPAGO_SUCCESS = 'store/FETCH_FORMASDEPAGO_SUCCESS';
export const FETCH_MEDIOS_SUCCESS = 'store/FETCH_MEDIOS_SUCCESS';
export const FETCH_OPERADORES_SUCCESS = 'store/FETCH_OPERADORES_SUCCESS';
export const FETCH_TARJETAS_SUCCESS = 'store/FETCH_TARJETAS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'store/FETCH_ITEMS_FAILURE';

export const TOASTS_ADD = 'app/TOASTS_ADD';
export const TOASTS_REMOVE = 'app/TOASTS_REMOVE';