import React, { useState, useEffect, useRef } from 'react';
import { styled, createStyles } from '@mui/material/styles';
import '../../../App.css';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridCellEditStopReasons, GridCellEditStopParams } from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid';
// import addProducto from '../Productos/addProducto'
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Productos from '../../Productos/'

import { Routes, Route, useParams, useNavigate  } from "react-router-dom";

import ProductDetails from '../../Productos/details2';

import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as itemsSelector from '../../../state/orders/selectors';
import * as itemsActions  from '../../../state/orders/actions';

import * as storeSelector from '../../../state/store/selectors';
import * as storeActions  from '../../../state/store/actions';

import currencyFormat from '../../../utils/currencyFormat';

import RefreshIcon from '@mui/icons-material/Refresh';
import gridStyle from '../gridStyle';
import ConfirmDialog from '../../shared/confirmDialog';

// import MaterialTable from 'material-table'

const useStyles = makeStyles(theme => ({
    tab: { 
        '& .MuiTabPanel-root': {
          padding: '0px',
          },
        },
    }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/*
const deleteProducto = (e) => {
  e.stopPropagation();
  console.log(e)
}*/



const rows = [
    { id: 1, plu: 1222, cantidad: 1, Descripcion: 'Sumo Slicer Eternity', precioLista: 5200, precioContado: 5000, iibb: 0, descuento: 0 },

];

const FABstyle = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 99999
};

const fabStyle = {
  // position: 'absolute',
  // justifyContent: 'flex-end'
  // bottom: 16,
  right: 16,
};

const CustomPagination = () => {
  // const { state, apiRef, options } = useGridSlotComponentProps();
  return (
    <Grid container spacing={1} s={12} direction="column" style={{ backgroundColor: '#f5f6fa' }}  >
      <Grid item xs={12} >
        <Typography> aaa </Typography>
      </Grid>
    </Grid>
  );
}

export default function Items() {
    const params = useParams();
    const nroPedido = parseInt(params.nroPedido)
    const [isCart, setIsCart] = React.useState(false);
    const deleteRow = useRef();
    const [open, setOpen] = React.useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
    const [ itemDeleteText, setItemDeleteText ] = React.useState('');
    const [value, setValue] = React.useState('1');
    const navigate = useNavigate();
    const isNewPedido = useSelector(itemsSelector.getNewPedido());
    const isFacturado = useSelector(itemsSelector.getPedidoFacturado());
    const handleCloseConfirmDelete = () => {
      setOpenConfirmDelete(false)
    }
    console.log(isNewPedido)
    const handleClickOpen = () => {
      setIsCart(true);
      navigate('productos')
      setIsOpen(true);
      // setOpen(true);
      // navigate('/skus', { state: { isCart: true }})
      //dispatch(itemsActions.openSKUDrawer())
    };
  
    const handleClose = () => {
      setIsOpen(false);
      navigate('')
    };

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const validateCantidad = (cantidad) => {
      if(cantidad < 1){
        return false;
      }
      return true;
    }

    const validateDescuento = (cantidad) => {
      if(cantidad < 0){
        return false;
      }
      return true;
    }

    const handleRefrescarItems = () => {
      dispatch(itemsActions.fetchAll(nroPedido))
    }

    const handleEliminarItem = () => {
      dispatch(itemsActions.deleteItem(deleteRow.current , nroPedido))
    }

    const dispatch = useDispatch();
    const allItems = useSelector(itemsSelector.fetchAllItems());

    const columns = [
      { field: 'PLU', headerName: 'PLU', width: 70 },
      { field: 'Descripcion', headerName: 'Producto', width: 200, flex: 1},
      { field: 'Cantidad', headerName: 'Cant', width: 80, type: 'number', editable: isNewPedido, minValue: 1 , preProcessEditCellProps: (params) => {
        const isValid = validateCantidad(params.props.value);
        return { ...params.props, error: !isValid };
      }},
      { field: 'Precio_Unitario', headerName: 'Precio Unit.', width: 130, type: 'number', valueGetter: (params) => currencyFormat(params.row.Precio_Unitario) },
      { field: 'Cuota', headerName: 'Cuota', width: 130, type: 'number', valueGetter: (params) => currencyFormat(params.row.Precio_Unitario / params.row.Cuotas) },
      {
        field: 'Descuento', headerName: 'Desc.', type: 'number', width: 90, editable: isNewPedido, preProcessEditCellProps: (params) => {
        const isValid = validateDescuento(params.props.value);
        return { ...params.props, error: !isValid };
      } },
      {
        field: 'Importe',
        headerName: 'Importe',
        type: 'number',
        width: 130,
        valueGetter: (params) => currencyFormat(params.row.Importe)
      },
      {
        field: 'IIBBListaCC',
        headerName: 'IIBBListaCC',
        sortable: false,
        hide: true,
        options: {display: false, viewColumns: false, filter: false} 
      //   valueGetter: (params) => params.getValue(params.id, 'age') * 1.21,
      },  
      { field: 'test',   renderCell: (params) => {
          const { row } = params;

          const deleteProducto = (e) => {
            if(!isFacturado){
              e.stopPropagation();
              //let newObj = Object.assign({}, row , { Baja : true})
              setOpenConfirmDelete(true)
              setItemDeleteText(row.Descripcion)
              deleteRow.current = row.id;
            }
          };

        if(isNewPedido){
          return (<Button onClick={deleteProducto} >{ !isFacturado ? <DeleteIcon fontSize="small" /> : ''}</Button>)
        }else{
          return (<></>)
        }

         
      }, key:'action' ,  headerName: 'Accion',  width: 90,sortable: false, editable: true  },
    ];


    // console.log(items)
    // const stateCuotas = useSelector(itemsSelector.getCuotas());
    const [stateIsOpen, setIsOpen] = useState(false);
    const stateIsCart = useSelector(itemsSelector.getIsCart());


    const handleReloadItems = () => {
      dispatch(itemsActions.fetchAll())
    }

    useEffect(() => {
      //dispatch(itemsActions.getNroPedido)
      setIsCart(stateIsCart);
    },[])

    return (
    // <div style={{ height: 'calc(65vh - 65px)', width: '100%', position: 'relative' }}>
    <Grid container spacing={0} direction="row" style={gridStyle} >
      <Grid item xs={12}>
          <Button variant="outlined" style={{ visibility: isNewPedido == true && isFacturado == false ?  'visible' : 'hidden' }} onClick={handleClickOpen} >
            Agregar Producto
          </Button>
          <Button onClick={handleRefrescarItems} style={{ float : 'right' }} variant="outlined">
              <RefreshIcon/>
          </Button>
        <Box  sx={{
        height: 400,
        width: '100%',
        '& .MuiDataGrid-cell--editing': {
          bgcolor: 'rgb(255,215,115, 0.19)',
          color: '#1a3e72',
          '& .MuiInputBase-root': {
            height: '100%',
          },
        },
        '& .Mui-error': {
          bgcolor: (theme) =>
            `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
          color: (theme) => (theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f'),
        },
      }}>
            <DataGrid
                id="productosTable"
                style={{flex: 1, height: 380, overflow: 'auto'}}
                rows={allItems}
                experimentalFeatures={{ newEditingApi: true }}
                className="stripped"
                columns={columns}
                BaseButton={<Button>hola</Button>}
                stripped
                hideFooterPagination
                disableSelectionOnClick 
                onCellEditStop={(params, event) => {
                  console.log(params.reason)
                  if (params.reason === GridCellEditStopReasons.cellFocusOut) {
                    event.defaultMuiPrevented = true;
                  }
                  if (params.reason === 'enterKeyDown') {
                    let itemId = params.id;
                    let value = event.target.value;
                    let field =  params.field;
                    let itemFound = allItems.find((item)=> { return item.id == itemId } )
                    let newObject =  Object.assign({}, itemFound , { [field] : Number(value) } )
                    console.log(value)
                    if(value){
                      dispatch(itemsActions.updateItem(newObject)).then(
                        () => { dispatch(itemsActions.fetchAll(nroPedido)) }
                      )
                    }
                  }
                }}
            >
                <Button>hola</Button>
            </DataGrid>
          </Box>
              {/* <MaterialTable
                columns={columns}
                data={rows}
                title="Demo Title"
              />               */}
              {/* <Fab color="green" aria-label="add" style={{}}>
                  <AddIcon onClick={handleClickOpen}/>
              </Fab>               */}
      {/* <Productos open={open} handleOpen={handleClickOpen} handleClose={handleClose}/> */} 
      </Grid>
      <ConfirmDialog
          title="Eliminar Item"
          open={openConfirmDelete}
          setOpen={setOpenConfirmDelete}
          onConfirm={handleEliminarItem}
        >
          ¿ Esta seguro que de desea eliminar el item  {itemDeleteText} ? 
      </ConfirmDialog>
      <Dialog
        // fullScreen
        open={stateIsOpen}
        onClose={handleClose}
     
        TransitionComponent={Transition}
        disablePortal 
        container={() => document.getElementById('main')}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }
        }}
        PaperProps={{
          sx: {
            // m: 0,
            // top: 10,
            // left: 10,
            minWidth: '95%'
          }
        }}     
        // style={{width: 800}}
        >
        <Routes>
            <Route path="/productos" element={<Productos isModal={true} handleClose={handleClose} />} /> 
            <Route path="/productos/:skuId"  element={<ProductDetails isModal={true} handleClose={handleClose} />} /> 
        </Routes>

      </Dialog>
    </Grid>

    // </div>
    );
}

// export default Productos;