import Api from '../Api';
 import * as appActions from '../../state/app/actions';

import { ADD, ADDPRICELISTS, FETCHING_SKUS, RECEIVED_ALL_SKUS, REQUEST_ALL_SKUS, FETCH_SKUS_SUCCESS, FETCH_SKUS_FAILURE, ADDINSTALLMENTS} from './constants';

export const add = skus => (dispatch) => {
  // console.log(users);
  // if (typeof users === 'number') dispatch({ type: USER_QUEUE_ADD, id: skus });
  // else dispatch({ type: ADD, data: skus });
};

export const fetchByCategory = cat => async (dispatch) => {
  const { data } = await Api.req.get(`/skus/category/${cat.trim()}`);
  dispatch({ type: ADD, data: { [data.id]: data } });

  return { ...data };
};

export const fetchAll = () => async (dispatch) => {
  dispatch({ type: REQUEST_ALL_SKUS });
  console.log('pasa x fetch')
  try {
    const { data } = await Api.req.get(`/skus?suc=11`);
    const parsedData = await data.productos;
    if(parsedData){
      dispatch({ type: FETCH_SKUS_SUCCESS, payload: parsedData })
    }
  }
  catch (err) {
    console.log(err)
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_SKUS_FAILURE, error: (error || err) });
  }

};

export const fetchPriceListsBySku = skuId => async (dispatch) => {
  console.log('llego a fetchPriceListsBySku')
  dispatch({ type: REQUEST_ALL_SKUS, data: {  } });
  try {
    const { data } = await Api.req.get(`/skus/sku/${skuId}/pricelists`);
    console.log(data)
    if (data){
      dispatch({ type: ADDPRICELISTS, payload: data.data })
    }
  }
  catch (err) {
    console.log(err)
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_SKUS_FAILURE, error: (error || err) });
  }
};


export const fetchPricesBySku = (skuId, listId) => async (dispatch) => {
  console.log('llego a fetchPricesBySku')
  dispatch({ type: REQUEST_ALL_SKUS, data: {  } });
  try {
    const { data } = await Api.req.get(`/skus/sku/${skuId}/pricelists/${listId}?suc=11`);
    const parsedData = await data.data;
    if(parsedData){
      dispatch({ type: ADDINSTALLMENTS, payload: parsedData })
    }
  }
  catch (err) {
    console.log(err)
    let error = err.message;
    if (err.response) {
      error = err.response.data.error;
    } else if (err.request) {
      error = err.request;
    }
    dispatch({ type: FETCH_SKUS_FAILURE, error: (error || err) });
  }
};