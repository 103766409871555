import React, {useState} from 'react';
import Cards from 'react-credit-cards';
import { Typography, Box, Tabs, Tab, InputLabel, Grid, TextField, Button, Dialog, Slide} from '@mui/material';

const AddCreditCard = () => {

    const [CCState, setCCState] = useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: '',
    })

    const handleInputFocus = (e) => {
        // setFocus( e.target.name );
        setCCState({ ...CCState, focus: e.target.name });
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCCState({ ...CCState, [name]: value });
    }

    return (
        <Grid container spacing={0} style={{ boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', padding: 15, borderRadius: 10, backgroundColor: '#fff', width: 700 }} >
            <Grid container spacing={1}  direction="row" >
                <Grid item xs={6} >
                    <Cards
                        cvc={CCState.cvc}
                        expiry={CCState.expiry}
                        focused={CCState.focus}
                        name={CCState.name}
                        number={CCState.number}
                    />
                </Grid>
                <Grid item xs={6} >
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '35ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                    <div>
                        <TextField
                            type="tel"
                            name="number"
                            label="Número de Tarjeta"
                            placeholder="Número de Tarjeta"
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            InputLabelProps={{ shrink: true }}
                            // value={ccValue}
                            size="small"
                        />
                        <TextField
                            type="tel"
                            name="name"
                            label="Titular"
                            placeholder="Titular"
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                        />
                        <Grid container spacing={1}  direction="row" >
                            <Grid item xs={6} >
                                <TextField
                                    type="tel"
                                    name="cvc"
                                    label="CVC"
                                    placeholder="CVC"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    style={{width: 150}}
                                />
                            </Grid>     
                            <Grid item xs={6} >                       
                                <TextField
                                    type="tel"
                                    name="expiry"
                                    label="Vencimiento"
                                    placeholder="Vencimiento"
                                    onChange={handleInputChange}
                                    onFocus={handleInputFocus}
                                    InputLabelProps={{ shrink: true }}
                                    size="small"
                                    style={{width: 145}}
                                />              
                            </Grid>
                        </Grid>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AddCreditCard;