import React from "react";
import { Box, Grid, FormControl, InputLabel, MenuItem, Button, TextField, Select } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Backdrop from '@mui/material/Backdrop';
import Api from "../../state/Api";
import CircularProgress from '@mui/material/CircularProgress';
import currencyFormat from "../../utils/currencyFormat";

const Subdiario = () => {

    const [fechaDesde, setFechaDesde] = React.useState(moment().startOf('month').toDate());
    const [fechaHasta, setFechaHasta] = React.useState(moment().endOf('month').toDate());
    const [ listadoPedidos, setListadoPedidos ] = React.useState([]);
    const [openLoader, setOpenLoader] = React.useState(false);

    const buscarPorSucursal = async () => {
        setOpenLoader(true)
        const searchParams = new URLSearchParams();
        searchParams.append("desde", moment(fechaDesde).format('YYYYMMDD'));
        searchParams.append("hasta", moment(fechaHasta).format('YYYYMMDD'));
        let dataPedidos = await Api.req.get(`store/sales?${searchParams}`);
        setListadoPedidos(dataPedidos.data.data)
        setOpenLoader(false)
    }
    
    const rowIcon = {
        minWidth: '20px'
    }

    const columns = [
        { field: 'Fecha', headerName: 'Fecha_Factura', width: 180, valueGetter: (params) => moment(params.row.Fecha).format('DD/MM/YYYY')  },
        { field: 'cantTickets', headerName: 'Cant Tickets', width: 140 },
        { field: 'VENTAS', headerName: 'Ventas', width: 200, minValue: 1 },
        { field: 'cantNC', headerName: 'Cant NC', width: 140 },
        { field: 'NotasDeCredito', headerName: 'Notas de Crédito', width: 140, valueGetter: (params) => moment(params.row.Fecha).format('DD/MM/YYYY') },
        { field: 'Neto', headerName: 'Neto', width: 140, valueGetter: (params) => currencyFormat(params.row.Neto) },
    ];

    return (
        <>
        <Box sx={{ padding : '10px' }}>
            <h3>Subdiario</h3>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2} sx={{ marginTop: '20px' }} >
                <Grid item xs={2}>
                    <DesktopDatePicker
                        label="Fecha"
                        inputFormat="dd/MM/yyyy"
                        value={fechaDesde}
                        fullWidth={true}
                        onChange={(value) => { setFechaDesde(value) }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Grid>
                <Grid item xs={2} >
                    <DesktopDatePicker
                        label="Hasta"
                        inputFormat="dd/MM/yyyy"
                        value={fechaHasta}
                        fullWidth={true}
                        onChange={(value) => { setFechaHasta(value) }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button size="large" disabled={openLoader} onClick={buscarPorSucursal} startIcon={<SearchIcon />} variant="contained" > Buscar </Button>
                </Grid>
            </Grid>   
            </LocalizationProvider>
            <hr />
        </Box>
        <Box sx={{ position: 'relative' }}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, position: "absolute" }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <DataGrid
                className="stripped"
                style={{ flex: 1, height: '400px', overflow: 'auto' }}
                rows={listadoPedidos}
                getRowId={(row) => row.Fecha}
                columns={columns}
                hideFooterPagination
                disableSelectionOnClick
            >
            </DataGrid>
        </Box>   
    </>
    )
}

export default Subdiario;

