import React, { useState, useEffect } from 'react';
import { Button , DialogActions , TextField, Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText, Dialog , DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import Api from '../../../state/Api';
import { useDispatch } from 'react-redux';
import * as storeActions from '../../../state/store/actions';

const NuevoTelefonoModal = (props) => {
    const handleClose = () => {
        props.onClose()
    }
    const [errors, setErrors] = useState([]);
    const [Tipo, setTipo] = useState('');
    const [Compania, setCompania] = useState('');
    const [Prefijo, setPrefijo] = useState('');
    const [Telefono, setTelefono] = useState('');
    const [listadoTipos, setListadoTipos] = useState([]);
    const [listadoCompanias, setListadoCompanias] = useState([]);
    const [validForm, setValidForm] = useState(false)
    const dispatch = useDispatch();

    const validateCampos = () => {
        console.log(Tipo,'esto es tipo')
        let tmp = {}
        tmp.Tipo = Tipo ? '' : 'Agregue Tipo';
        tmp.Compania = Compania ? '' : 'Agregue Compania';
        tmp.Prefijo = Prefijo ? '' : 'Agregue Prefijo';
        tmp.Telefono = Telefono ? '' : 'Agregue Telefono';

        setErrors({...tmp})

        return Object.values(tmp).every( x => x == '')
        
    }

    useEffect(()=> {
        
        if(validateCampos()){
            setValidForm(true)
        }
        
    },[Tipo,Compania,Prefijo, Telefono])

    const saveTelefono = async () => {
        const { data } = await Api.req.post(`/customers/${props.tipo}/${props.nro_docu}/telefonos`,{ "tipoTelefono": Tipo, "idCompania" : 1 ,"prefijo" : Number(Prefijo) , "telefono" : Number(Telefono) });
        dispatch(storeActions.addToast('Se ha agregado el número telefónico'))
        props.handleRefreshTelefonos()
        props.onClose()
      
    }

    useEffect(() => {
        let getData = async () => {
            if(props.open){
                let tiposTelefono = await Api.req.get(`/data/tiposTelefono`);
                setListadoTipos(tiposTelefono.data.data)

                let tiposCampania = await Api.req.get(`/data/companiaTelefonica`);
                setListadoCompanias(tiposCampania.data.data)
            }
        }
       
        getData()

    } ,[props.open])

    return(
        <Dialog
            maxWidth="md"
            open={props.open}
            onClose={handleClose}
            fullWidth={true}
        >
            <DialogTitle>Agregar Telefono</DialogTitle>
            <DialogContent>
            <Grid container sx={{paddingTop:'10px'}}>
                <Grid item sm={3} >
                    <FormControl error={errors.Tipo} fullWidth={true} sx={{ minWidth: 120 }}>
                        <InputLabel id="partido-label">Tipo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={Tipo}
                            label="Tipo"
                            onChange={ (e) => setTipo(e.target.value)}
                        >
                            {listadoTipos ? listadoTipos.map(
                                (item,index)=> (<MenuItem key={index} value={item.Codigo}>{item.Descripcion}</MenuItem>)
                            ) : ''}
                        </Select>
                        <FormHelperText>{errors.Tipo? errors.Tipo : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item sm={3} >
                    <FormControl error={errors.Compania} fullWidth={true} sx={{ minWidth: 120 }}>
                        <InputLabel id="partido-label">Compañía</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            fullWidth={true}
                            value={Compania}
                            label="Compañía"
                            onChange={(e) => setCompania(e.target.value)}
                        >
                            {listadoCompanias ? listadoCompanias.map(
                                (item,index)=> (<MenuItem key={index} value={item.IdCompañia}>{item.Descripcion}</MenuItem>)
                            ) : ''}
                        </Select>
                        <FormHelperText>{errors.Compania? errors.Compania : ''}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item sm={3} >
                    <TextField
                        value={Prefijo} 
                        onChange={(e) => setPrefijo(e.target.value)}
                        id="cuil"
                        label="Prefijo"
                        defaultValue="Ingrese"
                        {...(errors.Prefijo && { error:true, helperText : errors.Prefijo }) }
                    />
                </Grid>
                <Grid item sm={3} >
                    <TextField
                        value={Telefono} 
                        onChange={(e) => setTelefono(e.target.value)}
                        id="cuil"
                        label="Telefono"
                        defaultValue="Ingrese"
                        {...(errors.Telefono && { error:true, helperText : errors.Telefono }) }
                    />
                </Grid>
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary"  variant="contained">Cerrar</Button>
                <Button onClick={saveTelefono} disabled={!validForm} variant="contained">Guardar</Button>
            </DialogActions>
        </Dialog>
      )
}

export default NuevoTelefonoModal;