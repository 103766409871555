import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Api from '../../../state/Api';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch } from 'react-redux';
import * as storeActions from '../../../state/store/actions';
import * as ordersActions from '../../../state/orders/actions';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PrintIcon from '@mui/icons-material/Print';
import SendIcon from '@mui/icons-material/Send';
import ModalMail from "./ModalMail";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const steps = [
    'Comunicacion AFIP',
    'Guardando Pedido',
    'Generando Factura',
];


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height : 450,
    paddingBottom : '10px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalFacturar = (props) => {
    const dispatch = useDispatch();
    let datosFactura = props.facturarJson;

    const [activeStep, setActiveStep] = React.useState(0);
    const [stepError, setStepError ] = React.useState(10);
    const [isOpenModalMail, setIsOpenModalMail] = React.useState(false);
    const [facturaUrl, setFacturaUrl] = React.useState();
    const [ nroFactura, setNroFactura ] = React.useState(null);
    const [ openConfirmacion, setOpenConfirmacion ] = React.useState(false);

    const resetStep = (step) => {
        setStepError(10);
        if(step == 0) { firstStep() }
        if(step == 1) { secondStep() }
        if(step == 2) { thirdStep() }
    }

    const handleEnviarPorMail = async () => {
        setIsOpenModalMail(true)
    }

    const firstStep = async () => {

        try {
            const { data } = await Api.req.post(`/orders/order/${props.nroPedido}/afip`, datosFactura);
            setNroFactura(datosFactura.Tipo_Factura + '-' + data.data.nroFactura);
            datosFactura.nroFactura = data.data.nroFactura;
            datosFactura.CAE = data.data.CAE;
            datosFactura.vencimientoCAE = data.data.vencimientoCAE;
            datosFactura.codigoBarras = data.data.codigoBarras;
            dispatch(ordersActions.setNroFactura(data.data.nroFactura))
        } catch (error) {
            setStepError(0)
            return;
        }

        secondStep()
    }

    const secondStep = async () => {
        setActiveStep(1)
       
        try {
            //throw new Error('Error data fetch');
            const { data } = await Api.req.post(`/orders/order/${props.nroPedido}/save`, datosFactura);
        } catch (error) {
            setStepError(1)
            return;
        }

        thirdStep()
    }

    const thirdStep = async () => {
        setActiveStep(2)
        try {
            const { data } = await Api.req.post(`/orders/order/${props.nroPedido}/invoice`, datosFactura);
            setFacturaUrl(data.url)
            setActiveStep(3)
            dispatch(ordersActions.setPedidoFacturado())
            dispatch(storeActions.addToast('Se ha llevado a cabo la factura exitosamente'))
        } catch (error) {
            setStepError(2)
            return;
        }
    }
   
    const getIcons = (step) => {
        if(stepError == step){
            return (<> <ErrorOutlineIcon sx={{ fontSize: '50px', verticalAlign: 'middle', marginLeft: '20px', color : 'red' }} /> <Button variant="contained" onClick={() => resetStep(step)} sx={{ cursor: 'pointer' }}>Reintentar </Button> </>)
        }

        if(step == activeStep){
            return (<CircularProgress sx={{ verticalAlign: 'middle', marginLeft: '20px' }} />)
        } else if (activeStep > step) {
            return (<CheckCircleOutlineIcon sx={{ fontSize: '50px', color: 'green', verticalAlign : 'middle' , marginLeft : '20px' }} />)
        }
    }

    const handleCloseModal = () => {
        setOpenConfirmacion(true)
    }

    const handleCloseModalMail = () => {
        setIsOpenModalMail(false)
    }

    const handleImprimirFactura = () => {
        window.open(facturaUrl, "_blank", "noreferrer");
    }

    const handleCloseFacturacion = () => {
        props.onClose()
        setOpenConfirmacion(false)
        setActiveStep(0)
        setStepError(10)
    }

    const handleCloseConfirmacion = () => {
        setOpenConfirmacion(false)
    }

    useEffect( () => {
        if(props.open){
            firstStep()
            setActiveStep(0)
            //setActiveStep(3)
        }

    }, [props.open] )

    return (

        <Modal
            open={props.open}
            onCloseModal={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        
          <Box sx={style} >
                <Dialog
                    open={openConfirmacion}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                       Cerrar Ventana
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                           Esta seguro que desea cerrar la ventana de facturación
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConfirmacion}>No</Button>
                        <Button onClick={handleCloseFacturacion} autoFocus>
                            Si
                        </Button>
                    </DialogActions>
                </Dialog>
                <ModalMail email={datosFactura.cliente.E_Mail}  nroPedido={props.nroPedido} nroFactura={nroFactura}  CloseModalMail={handleCloseModalMail} open={isOpenModalMail} />
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <Box sx={{ display: 'flex', marginTop :'20px' }}>
                    <Box m="auto">
                        {steps[0]}&nbsp; { getIcons(0) }
                        
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box m="auto">
                        {steps[1]} {getIcons(1)}
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box m="auto">
                        {steps[2]} {getIcons(2)}
                    </Box>
                </Box>
                { activeStep == 3 ?
                <Box sx={{ display: 'flex', marginTop: '20px' }}>
                    <Box m="auto">
                        <Button sx={{ marginRight : '10px'}} onClick={handleImprimirFactura} variant="outlined" startIcon={<PrintIcon />}>
                            Imprimir
                        </Button>
                        <Button variant="contained" onClick={handleEnviarPorMail} endIcon={<SendIcon />}>
                            Enviar por Mail
                        </Button>
                    </Box>
                </Box> : '' }
                {activeStep == 3 || stepError == 0 || stepError == 1 || stepError == 2  ?
                <Box sx={{ display: 'flex', marginTop: '20px', marginBottom : '10px' }}>
                    <Button onClick={handleCloseModal} variant="contained" color="error" sx={{ marginBottom: '10px', float : 'right' }}>
                        Cerrar
                    </Button>
                </Box> : ''
                }
                 
                 
            </Box>
           
        </Modal>
    )
}

export default ModalFacturar;