import React,{ useEffect, useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Api from '../../../state/Api';
import { useDispatch } from 'react-redux';
import * as storeActions from '../../../state/store/actions';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    paddingBottom: '10px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalMail = (props) => {
    const dispatch = useDispatch();
    const [email , setEmail] = React.useState('');
    const tieneMail = props.email ? true : false;
    const initMail = tieneMail ? 'existente' : 'otro';
    const [tipoEnvio, setTipoEnvio] = React.useState(initMail);
    const [errors, setErrors] = useState([]);

    const handleChange = (event) => {
        setTipoEnvio(event.target.value);
    };

    const handleCloseModal = () => {
        props.CloseModalMail();
    }

    const handleGuardarEmail = async () => {
        if(tipoEnvio == 'existente'){
            try {
                let dataDoc = await Api.req.post(`orders/order/${props.nroPedido}/invoice/${props.nroFactura}/send2email`, { email: props.email, newEmail: false });
                dispatch(storeActions.addToast('Se ha enviado la factura al email del cliente'))
                props.CloseModalMail();
            } catch (error) {
                dispatch(storeActions.addToast('Ha habido un error : ' + error, 'error'))
            }
        }else{
            try {
                let dataDoc = await Api.req.post(`orders/order/${props.nroPedido}/invoice/${props.nroFactura}/send2email`, { email: email, newEmail: true });
                dispatch(storeActions.addToast('Se ha enviado la factura al email del cliente'))
                props.CloseModalMail();
            } catch (error) {
                dispatch(storeActions.addToast('Ha habido un error : ' + error, 'error'))
            }
        }
        
    }

    const getLabelDefaultMail = () => {
        return 'Enviar Mail a '+ props.email;
    } 

    const sendDisabled = () => {
        if (tipoEnvio == 'existente') {
            return false
        }
        console.log(errors)
        if (tipoEnvio == 'otro' && Object.values(errors).every(x => x !== '')){
            return true;
        }
        return false;
    }

    const getButtonText = () => {
        if(tipoEnvio == 'existente'){
            return 'ENVIAR FACTURA';
        }
        if(tipoEnvio == 'otro'){
            return 'GUARDAR Y ENVIAR FACTURA';
        }
        
    }

    const validate = () => {
        let tmp = {};
        tmp.email = /^\S+@\S+\.\S+$/.test(email) ? '' : 'Ingrese un email válido';
        console.log(tmp)
        setErrors({ ...tmp })
    }

    useEffect( () => {
        validate()
    },[email] )

    return <Modal
                open={props.open}
            >
                <Box sx={style} >
                    <h2 id="parent-modal-title">Guardar Email </h2>
                    <FormControl>
                        <FormLabel id="demo-radio-buttons-group-label">Seleccione</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={tipoEnvio}
                            onChange={handleChange}
                            name="radio-buttons-group"
                        >
                                {tieneMail ? <FormControlLabel value="existente" control={<Radio />} label={getLabelDefaultMail()} /> : ''} 
                                <FormControlLabel value="otro" control={<Radio />} label="Enviar a otra dirección de Email" /> 
                                <TextField
                                    id="outlined-helperText"
                                    onChange={(e) => setEmail(e.target.value)}
                                    disabled={ tipoEnvio == 'existente' }
                                    value={email}
                                    type="email"
                                    sx={{ width: '350px' }}
                                    label="Email"
                                    defaultValue=""
                                    {...(tipoEnvio == 'otro' && errors.email && { error: true, helperText: errors.email })}
                                />
                        </RadioGroup>
                    </FormControl>

                    
                     <br /><br />
                    <Button variant="contained"  color="error" onClick={handleCloseModal} >Cancelar</Button>
                    <Button variant="contained" sx={{ float: 'right' }} disabled={sendDisabled()} onClick={handleGuardarEmail} >{getButtonText()}</Button>

                </Box>
            </Modal>
}

export default ModalMail;