import React, { useState, useEffect } from 'react';
import { Typography, Box, Tabs, Tab, ButtonBase, Grid, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Slide, MenuItem, InputLabel, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CCIcon from '@mui/icons-material/CreditCard';
import WalletIcon from '@mui/icons-material/AccountBalance';
import CashIcon from '@mui/icons-material/AttachMoney';
import QRIcon from '@mui/icons-material/QrCode';
import SvgIcon from '@mui/material/SvgIcon';
import Receipt from '@mui/icons-material/Receipt';
import Backdrop from '@mui/material/Backdrop';
import Api from '../../../state/Api';
import { useDispatch, useSelector, useAction, shallowEqual } from 'react-redux';
import * as itemsSelector from '../../../state/orders/selectors';
import * as skusSelector from '../../../state/skus/selectors';
import * as storeActions from '../../../state/store/actions';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddCreditCard from './addCreditcard'
import 'react-credit-cards/es/styles-compiled.css';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import currencyFormat from '../../../utils/currencyFormat';


// Tarjetas SVG
import { ReactComponent as VisaIcon } from './icons/visa.svg';
import { ReactComponent as MastercardIcon } from './icons/mastercard.svg';
import { ReactComponent as AmexIcon } from './icons/amex.svg';
import { ReactComponent as NaranjaIcon } from './icons/naranja-x-1.svg';

//Bulleteras SVG
import { ReactComponent as TodoPagoIcon } from './icons/todopago.svg';
import { ReactComponent as MercadoPagoIcon } from './icons/mercado-pago.svg';
import { ReactComponent as BimoIcon } from './icons/bimo_8.svg';
import { ReactComponent as CuentaDNIIcon } from './icons/cuenta-dni.svg';
import { ReactComponent as ModoIcon } from './icons/modo-2021.svg';
import { ReactComponent as RapipagoIcon } from './icons/rapipago.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';

import jwt_decode from "jwt-decode";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 180,
    padding: 5,
    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        border: '4px solid currentColor',
      },
    },
  }));
  
  const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 50%',
  });
  
  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));
  
  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  }));
  
  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  

const CobrosModal = (props) => {

    const [view, setView] = React.useState('visa');
    const [view1, setView1] = React.useState('mp');
    const [view2, setView2] = React.useState('rapipago');

    const [value, setValue] = React.useState(0);
    const [imgSrc, setImgSrc] =  React.useState(undefined)
    const [openBackDrop, setOpenBackDrop] = React.useState(false);
    const currentFormaPago = useSelector(itemsSelector.getCurrentFormaPago());

    const [currentCard ,setCurrentCard] = React.useState('')
    const total = useSelector(itemsSelector.getTotal());
    const allSkus = useSelector(itemsSelector.fetchAllItems());
    const dispatch = useDispatch();
    const payments = useSelector(itemsSelector.getPayments());
    const sumaPayments = payments.reduce((sum, val) =>  sum + val.Importe  ,0) 
    const tienePaymentEfectivo = payments.find((payment) => payment.formapago == 'EFECTIVO' ) ?  true : false; 
    const [ cards, setCards ] = React.useState([])
    const saldoRestante = total.importeTotal - sumaPayments;

    var token = localStorage.getItem('jwt');
    var decoded = jwt_decode(token);

    const [ fechaAutorizacion , setFechaAutorizacion ] = React.useState( dayjs() )

    const postQr = async () => {
      const  totalQr = Number(total.importeTotal.toFixed(2));
      setOpenBackDrop(true)
      try{
        const { data } = await Api.req.post(`payment/mercadopago/sucursal/${decoded.suc}/${props.nroPedido}/qr`,
        { "Importe": totalQr, "producto" : allSkus[0]["Descripcion"] });
        setImgSrc(data.data)
        setOpenBackDrop(false)
        dispatch(storeActions.addToast('Se ha generado el QR de mercadopago'))
      }catch(error){
        dispatch(storeActions.addToast('Ha habido un error al generar el QR :'+error,'error'))
      }
    }

    const putQr = async () => {
      const  totalQr = Number(total.importeTotal.toFixed(2));
      setOpenBackDrop(true)
      try{
        const { data } = await Api.put.post(`payment/mercadopago/sucursal/${decoded.suc}/${props.nroPedido}/qr`,
          { "Importe": totalQr, "producto" : allSkus[0]["Descripcion"] });
          console.log(data.data)
          setOpenBackDrop(false)
         dispatch(storeActions.addToast('Se ha pasado al marcador de mercadopago'))
      }catch(error){
        dispatch(storeActions.addToast('Ha habido un error al generar el QR:'+error,'error'))
      }
    }

    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
    };

    const handleChange = (event, nextView) => {
      setView(nextView);
    };
    const handleChange1 = (event, nextView) => {
        setView1(nextView);
    };
    const handleChange2 = (event, nextView) => {
        setView2(nextView);
    };      

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const handleAgregarCobro = async () => {
      var params;
      //POSNET
      if(value == 0){
         params = {
          "codigoFP": 2,
          "fecha": dayjs(fechaAutorizacion).format('DD/MM/YYYY'),
          "importe": Number(importe),
          "codTarjeta": currentCard,
          "codAutorizacion": codAutorizacion,
          "nroCupon": nroCupon,
          "lote": lote
        };
      }
      //EFECTIVO
      if(value == 2){
        let importeSend = importe >= saldoRestante ? saldoRestante : importe;
        params = {
          "codigoFP": 5,
          "importe": Number(importeSend)
        };
      }
      
      const response = await Api.req.post(`/orders/order/${props.nroPedido}/payments`, params);

      props.closeUpdateModal();
    }

    const handleCopiarImporteTotal = () => {
      setImporte(total.importeTotal)
    }

    const handleSaldoRestante = () => {

      setImporte(total.importeTotal - sumaPayments)
    }

    const checkCobrosDisabled = () => {
      let validImporte = importe <= saldoRestante;

      if(value == 0){
        if (fechaAutorizacion && importe && codAutorizacion && nroCupon && lote && confirmo && currentCard && validImporte) {
          return false;
        } else {
          return true
        }
      }

      if(value == 3 ){
        if(importe){
          return false;
        }else{
          return true;
        }
      }
     
    }

    const getVuelto = () => {
      let suma = Number(total.importeTotal) - (Number(sumaPayments) + Number(importe))
      if (Math.sign(suma) == -1 ){
        return -suma;
      }
      return 0
    }

    const [codAutorizacion, setCodAutorizacion] = React.useState(null);
    const [nroCupon, setNroCupon] = React.useState(null);
    const [lote, setLote] = React.useState(null);
    const [importe, setImporte] = React.useState(null)
    const vuelto = getVuelto(); 
    const [confirmo, setConfirmo] = React.useState(false)

    const handleChangeConfirmo = () => {
      setConfirmo(!confirmo)
    }

    const todoPagoButton = () => {
      return <ToggleButton value="tp" aria-label="list" color="standard" size="small" sx={{ height: 50, width: 200, display :'none' }}> 
                  <SvgIcon component={TodoPagoIcon} inheritViewBox sx={{ fontSize: 100, marginLeft: -2 }}/>
              </ToggleButton> 
    }

    const handleChangeCard = (event) => { setCurrentCard(event.target.value); };
  
    const locale = [ 'en-gb'];

    useEffect( () => {
      if(currentFormaPago == 61){
        setValue(1)
      }else{
        setValue(0)
      }
    }, [currentFormaPago])

    useEffect( () => {
        const fetchData = async () => {
          // get the data from the api
          const response = await Api.req.get('https://pixdev.tevecompras.com/store/cards');
          // convert the data to json
          setCards(response.data.data);
        }

        // call the function
        fetchData()
        // make sure to catch any error
        .catch(console.error);;
      }, []
    )

  const labelTopPadding = '8.5px';

  return (
        <div>
          <Backdrop
            sx={{ color: '#fff', position :'absolute', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
            <Grid container spacing={0} direction="row" style={{ boxShadow: '0 0 8px 0 rgba(0, 0, 0, 0.16)', border: '#ddd 1px solid', background: '#fff', padding: 15, borderRadius: 10 }} >
                <Grid item xs={12} style={{height: 520}}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                        <Tab label="Posnet" iconPosition="start"  icon={<CCIcon />} {...a11yProps(0)} />
                        <Tab label="Billetera" iconPosition="start"  icon={<QRIcon />} {...a11yProps(1)} />
                        <Tab disabled={tienePaymentEfectivo} label="Efectivo" iconPosition="start" icon={<CashIcon />} {...a11yProps(2)} />  
                        <Tab label="Offline" iconPosition="start" icon={<Receipt />} {...a11yProps(3)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4} >
                                          <InputLabel style={{ paddingTop: labelTopPadding }} id="tarjetaLabel" >Tarjeta</InputLabel>
                                        </Grid>
                                        <Grid item xs={8} >
                                            <Select
                                              value={currentCard}
                                              labelId="tarjetaLabel"
                                              onChange={handleChangeCard}
                                              size="small"
                                              style={{width : '300px'}}
                                              variant="outlined"
                                            >
                                              {cards && cards.map((fp, index) => {
                                                return (
                                                  <MenuItem key={index} value={fp.CODIGO}>{fp.DESCRIPCION}</MenuItem>
                                                )
                                              })}
                                            </Select>  
                                        </Grid>
                                        <Grid item xs={4} >
                                          <InputLabel style={{ paddingTop: labelTopPadding }} id="fechaLabel" >Fecha de Autorización</InputLabel>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr' >
                                                <DesktopDatePicker
                                                  
                                                  value={fechaAutorizacion}
                                                  style={{ height: '10px' }}
                                                  onChange={(newValue) => {
                                                    setFechaAutorizacion(newValue);
                                                  }}
                    renderInput={(params) => <TextField size="small" style={{ width: '210px' }} labelId="fechaLabel"  {...params} />}
                                                />
                                            </LocalizationProvider>
                                      </Grid>
                                      <Grid item xs={4}>
                                          <InputLabel style={{ paddingTop: labelTopPadding }} id="importeLabel">Importe</InputLabel>
                                          
                                      </Grid>
                                      <Grid item xs={8}>

                                        <TextField value={importe} 
                                        error={importe > saldoRestante}
                                        helperText={(importe > saldoRestante) && "Importe mayor al saldo : $" + saldoRestante}
                                        type="number"
                                        errorStyle={{ display: "inline" }}
                                        style={{width : '210px'}}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                        InputProps={{
                                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }} labelId="importeLabel" 
                                        onChange={(event) => { setImporte(event.target.value) }}  
                                        size="small"  />
                                          <Button sx={{ marginLeft : '10px' }} variant="contained" onClick={handleCopiarImporteTotal} >Importe</Button>
                                          {payments.length > 0 ? <Button sx={{ marginLeft: '10px' }} color="error" variant="contained" onClick={handleSaldoRestante} >Saldo</Button> : '' }
                                      </Grid>
                                      <Grid item xs={4}>
                                        <InputLabel style={{ paddingTop: labelTopPadding }} id="codigoLabel" >Código de Autorización</InputLabel>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <TextField value={codAutorizacion} labelId="codigoLabel" onChange={(event) => { setCodAutorizacion(event.target.value) }} size="small" InputLabelProps={{ shrink: true }} />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <InputLabel style={{ paddingTop: labelTopPadding }} id="cuponLabel" >Número de Cupón</InputLabel>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <TextField value={nroCupon} labelId="cuponLabel" onChange={(event) => { setNroCupon(event.target.value) }}  size="small" InputLabelProps={{ shrink: true }} />
                                      </Grid>
                                      <Grid item xs={4} >
                                        <InputLabel id="loteLabel" style={{ paddingTop: labelTopPadding }} >Lote</InputLabel>
                                      </Grid>
                                      <Grid item xs={8} >
                                        <TextField value={lote} labelId="loteLabel" onChange={(event) => { setLote(event.target.value) }} size="small" InputLabelProps={{ shrink: true }} />
                                      </Grid>
                                      <Grid item xs={8}>
                                        Confirmo que coinciden los datos de la tarjeta con la identidad del cliente del presente pedido.
                                      </Grid>
                                      <Grid item xs={4} >
                                        <Checkbox
                                          checked={confirmo}
                                          onChange={handleChangeConfirmo}
                                          inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                      </Grid>
                                </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <ToggleButtonGroup
                                // orientation="vertical"
                                value={view1}
                                exclusive
                                onChange={handleChange1}
                                size="small"
                                style={{ backgroundColor:"white" }}
                            >
                                <ToggleButton value="mp" aria-label="list" color="standard" size="small" sx={{ height: 50, width: 200 }}> 
                                    <SvgIcon component={MercadoPagoIcon} inheritViewBox sx={{ fontSize: 120 }}/>
                                </ToggleButton>                        
                            </ToggleButtonGroup> 
                            { view1 == 'tp' ? 
                                <Box
                                component="form"
                                sx={{
                                    '& .MuiTextField-root': { m: 1, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                                >
                                    <div>
                                        <TextField label="Nombre Titular" size="small" InputLabelProps={{ shrink: true }}/>
                                        <TextField label="Cod. Autorización" size="small" InputLabelProps={{ shrink: true }}/><TextField label="Lote" size="small" InputLabelProps={{ shrink: true }}/>
                                    </div>
                                </Box> 
                            : null }
                            { view1 == 'mp' ? 
                                <Box
                                    sx={{textAlign: 'center'}}
                                >
                                  <Box>
                                    <ImageButton 
                                        variant="contained" 
                                        startIcon={<QRIcon />} 
                                        // sx={{marginTop: -10, textAlign: 'center'}}
                                        fullWidth
                                        style={{
                                            width: 250,
                                            height: 250,
                                            marginTop: -10,
                                            overflow: 'hidden',
                                            // borderRadius: 20,
                                            textAlign: 'center'
                                          }}
                                    >
                                    {/* <ImageBackdrop className="MuiImageBackdrop-root" /> */}
                                    {imgSrc?  <img src={imgSrc} alt="" /> : <ImageSrc style={{ backgroundImage: 'url(./assets/QRdecoy.png)', backgroundSize:'contain', backgroundRepeat: 'no-repeat' }} /> }
                                   
                                    
                                        {!imgSrc? <Image>
                                          <Button 
                                            onClick={postQr}
                                            variant="contained" 
                                            startIcon={<QRIcon />} 
                                            sx={{borderRadius: 8, textAlign: 'right'}}
                                            fullWidth
                                            >GENERAR QR</Button>
                                        </Image> : ''}
                                    </ImageButton>
                                    </Box>
                                    <Box>
                                      {imgSrc? <Button  
                                        disabled 
                                        onClick={putQr}
                                        variant="contained" 
                                        startIcon={<QRIcon />} 
                                        sx={{borderRadius: 8, textAlign: 'right'}}>Pasar Qr</Button> : '' }
                                     </Box>
                                </Box>
                               
                            : null }
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Grid item xs={4}>
                        <InputLabel style={{ paddingTop: labelTopPadding }} id="importeLabel">Importe</InputLabel>

                      </Grid>
                      <Grid item xs={8}>

                        <TextField value={importe}
                          type="number"
                          style={{ width: '210px' }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }} labelId="importeLabel" onChange={(event) => { setImporte(event.target.value) }} size="small" />
                        <Button sx={{ marginLeft: '10px' }} variant="contained" onClick={handleCopiarImporteTotal} >Total</Button>
                        {payments.length > 0 ? <Button sx={{ marginLeft: '10px' }} color="error" variant="contained" onClick={handleSaldoRestante} >Saldo</Button> : '' } 
                      </Grid>
                      <Grid item xs={4}>
                        <InputLabel style={{ paddingTop: labelTopPadding }} id="importeLabel">Vuelto</InputLabel>

                      </Grid>
                      <Grid item xs={8}>

                        <TextField value={vuelto}
                          type="number"
                          style={{ width: '210px' }}
                          InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }} labelId="vueltoLabel" readonly size="small" />
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                      <ToggleButtonGroup
                        // orientation="vertical"
                        value={view2}
                        exclusive
                        onChange={handleChange2}
                        size="small"
                        style={{ backgroundColor: "white" }}
                      >
                        <ToggleButton value="rapipago" aria-label="list" color="standard" size="small" fullWidth sx={{ height: 50, width: 400 }}>
                          <SvgIcon component={RapipagoIcon} inheritViewBox sx={{ fontSize: 400, height: 100 }} />
                        </ToggleButton>
                      </ToggleButtonGroup>
                      {view2 == 'rapipago' ?
                        <Button
                          variant="contained"
                          startIcon={<Receipt />}
                          sx={{ borderRadius: 8, textAlign: 'right' }}
                          fullWidth
                        >GENERAR BOLETA DE PAGO</Button>
                        : null}
                    </TabPanel>
            </Grid>    
            <Box sx={{width : '100%'}}>
              <Button variant="contained" color="error" style={{ float: 'left' }} onClick={() => props.closeModal()}>Cerrar</Button>
              {value == 0 || value == 2 ? <Button variant="contained" style={{ float: 'right' }} disabled={checkCobrosDisabled()} onClick={handleAgregarCobro}>Agregar Cobro</Button> : '' } 
            </Box>
    </Grid>     
            <Dialog
                // fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                disablePortal 
                maxWidth="lg"
                // container={() => document.getElementById('main')}
            >
                <DialogTitle>AGREGAR TARJETA</DialogTitle>
                <DialogContent>
                    <AddCreditCard open={open} handleOpen={handleClickOpen} handleClose={handleClose}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>GUARDAR</Button>
                </DialogActions>
            </Dialog>
    </div>       
  );
}

export default CobrosModal;