import produce from 'immer';
import { ADD, ADD_ITEM_SUCCESS, FETCH, FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE, 
    OPENDRAWER, CLOSEDRAWER, UPDATE_ITEM_REQUEST, UPDATE_ITEM_SUCCESS, UPDATE_ITEM_FAILURE,
     UPDATE_CUOTAS_REQUEST, UPDATE_CUOTAS_SUCCESS, UPDATE_CUOTAS_FAILURE, SET_NRO_PEDIDO,
    FETCH_DATOS_CLIENTE_SUCCESS, FETCH_DATOS_PEDIDO_SUCCESS, FETCH_DATOS_PEDIDO_REQUEST,
    FETCH_DATOS_PEDIDO_FAILURE, SET_NEW_PEDIDO, SET_CURRENT_LISTA, RESET_FILTROS, DELETE_NEW_PEDIDO, INIT_PEDIDO,
    FETCH_PAYMENTS_PEDIDO_SUCCESS, SET_CURRENT_FORMAPAGO, SET_CURRENT_LISTA_CUOTAS, SET_TIPOS_FACTURA, SET_TIPO_FACTURA ,
    SET_CONDICIONES_IVA, SET_CONDICION_IVA, SET_TIPO_ENVIO, FETCH_STORE_ADDRESS_SUCCESS, COPY_ADDRESS_DATA, 
    SET_ADDRESS_DATA, SET_OPERADOR, SET_MEDIO, SET_NRO_FACTURA, SET_PEDIDO_FACTURADO, SET_IMPORTE } from './constants';


export const initialState = {
    loading: { isLoading : false  },
    user : null,
    orders: [],
    items: [],
    itemsFetching: false,
    errors: false,
    isCart: true,
    isOpen: false,
    pedido: { nroPedido: null, newPedido : false },
    datos : { cliente : {}, datosPedido : {  }, tiposFactura : [], nroFactura : null, facturado : false },
    cuotas: 1,
    total : { importeTotal: 0, valorCuota: 0, redondeo:0, descuentos: 0, subTotal: 0, cuotas: 1, writable: true }
};

const reducer = (state = initialState, action) => { return produce(state, (draftState) => {
    switch(action.type) {

        case SET_NRO_PEDIDO:
            draftState.pedido.nroPedido = action.payload.nroPedido;
            if (action.payload.cliente){
                draftState.datos.cliente = action.payload.cliente;
            }
            
        break; 
        
        case DELETE_NEW_PEDIDO:
            localStorage.setItem('newPedido', 'false');
            localStorage.removeItem('currentLista');
            draftState.pedido.newPedido = false;
            draftState.pedido.nroPedido = null;
            draftState.datos.cliente = null;
            draftState.datos.datosPedido = {}
        break;

        case SET_NEW_PEDIDO:
            draftState.pedido.nroPedido = action.payload.nroPedido;
            draftState.datos.cliente = action.payload.cliente;
            draftState.pedido.newPedido = true;
            draftState.datos.datosPedido = {}
            draftState.datos.datosPedido.condicionesIva = 3;
            draftState.datos.datosPedido.Importe1 = 0;
            draftState.datos.datosPedido.Importe2 = 0;
            draftState.datos.datosPedido.Importe3 = 0;
            draftState.datos.datosPedido.Envio_Preferencial = false;
            draftState.datos.datosPedido.Retira_Local = true;
            draftState.datos.nroFactura = null;
            draftState.datos.facturado = false;
            //localStorage.setItem('newPedido', 'true');
        break;

        case SET_TIPO_FACTURA:
            draftState.datos.datosPedido.Tipo_Factura = action.payload;
        break;

        case SET_NRO_FACTURA:
            draftState.datos.nroFactura = action.payload;
        break;

        case SET_PEDIDO_FACTURADO:
            draftState.datos.facturado = true;
        break;

        case INIT_PEDIDO:
            /*draftState.pedido.newPedido = localStorage.getItem('newPedido') == 'true' ? true:false;
            draftState.datos.datosPedido.currentLista = localStorage.getItem('currentLista');
            draftState.datos.datosPedido.currentFormaPago = localStorage.getItem('currentFormaPago');
            draftState.datos.datosPedido.currentListaCuotas = JSON.parse(localStorage.getItem('currentListaCuotas'));*/
        break;

        case UPDATE_CUOTAS_REQUEST:
            draftState.loading.isLoading = false;
        break;
        
        case RESET_FILTROS:
            draftState.datos.datosPedido.currentLista = null;
            draftState.datos.datosPedido.Cuotas = null;
            draftState.datos.datosPedido.currentFormaPago = null;
            draftState.datos.datosPedido.currentListaCuotas = null;
            /*localStorage.removeItem('currentLista');
            localStorage.removeItem('currentFormaPago');
            localStorage.removeItem('currentListaCuotas');*/
        break;

        case FETCH_STORE_ADDRESS_SUCCESS:
            draftState.datos.datosPedido.storeAddress = action.payload.Direccion;
            draftState.datos.datosPedido.storeInfo = action.payload;
        break

        case FETCH_PAYMENTS_PEDIDO_SUCCESS:
            draftState.datos.datosPedido.payments = action.payload;
        break;

        case SET_CURRENT_LISTA:
            draftState.datos.datosPedido.currentLista = action.payload;
            //localStorage.setItem('currentLista', action.payload);
        break;  
           
        case SET_TIPOS_FACTURA:
            draftState.datos.tiposFactura = action.payload;
        break;  

        case SET_ADDRESS_DATA:
            let datos =  action.payload;
            draftState.datos.datosPedido.Ape_Entrega = datos.Ape_Entrega;
            draftState.datos.datosPedido.Nom_Entrega = datos.Nom_Entrega;
            draftState.datos.datosPedido.Calle_Entrega = datos.Calle_Entrega;
            draftState.datos.datosPedido.Nro_Entrega = datos.Nro_Entrega;
            draftState.datos.datosPedido.Edif_Entrega = datos.Edif_Entrega;
            draftState.datos.datosPedido.CP_Entrega = datos.CP_Entrega;
            draftState.datos.datosPedido.Loca_Entrega = datos.Loca_Entrega;
            draftState.datos.datosPedido.Parti_Entrega = datos.Parti_Entrega;
            draftState.datos.datosPedido.Provi_Entrega = datos.Provi_Entrega;
            draftState.datos.datosPedido.idProvi_Entrega = datos.idProvi_Entrega;
            draftState.datos.datosPedido.idParti_Entrega = datos.idParti_Entrega;
        break;  

        case SET_IMPORTE:
            draftState.datos.datosPedido["Importe" + action.payload.nroImporte] = action.payload.valor;
        break;

        case SET_OPERADOR:
            draftState.datos.datosPedido.OperadorCod = action.payload;
        break;

        case SET_MEDIO:
            draftState.datos.datosPedido.codMedio = action.payload;
        break;

        case SET_TIPO_ENVIO:
            if (action.payload == 'Retiro'){
                draftState.datos.datosPedido.Envio_Preferencial = false;
                draftState.datos.datosPedido.Retira_Local = true;
            }
            if (action.payload == 'Envio') {
                draftState.datos.datosPedido.Envio_Preferencial = true;
                draftState.datos.datosPedido.Retira_Local = false;
            }
            
        break;  

        case SET_CONDICIONES_IVA:
            draftState.datos.condicionesIva = action.payload;
        break;  
        
        case SET_CONDICION_IVA:
            draftState.datos.datosPedido.condicionIva = action.payload;
        break;  
        
        case SET_CURRENT_LISTA_CUOTAS:
            draftState.datos.datosPedido.currentListaCuotas = action.payload;
            //localStorage.setItem('currentListaCuotas', JSON.stringify(action.payload) );
        break;

        case SET_CURRENT_FORMAPAGO:
            let dataFormaPago =  action.payload;
            draftState.datos.datosPedido.currentFormaPago = dataFormaPago.formaPago;
            draftState.datos.datosPedido.currentFormaPagoString = dataFormaPago.formaPagoString;
            //localStorage.setItem('currentFormaPago', action.payload);
        break;

        case UPDATE_CUOTAS_SUCCESS:
            draftState.cuotas = action.payload;
            draftState.datos.datosPedido.Cuotas = action.payload;
            draftState.total.cuotas = action.payload;
            draftState.loading.isLoading = false;
        break;

        case UPDATE_CUOTAS_FAILURE:
            draftState.loading.isLoading = false;
        break;

        case FETCH_DATOS_CLIENTE_SUCCESS:
            draftState.datos.cliente = action.payload;
        break;

        case FETCH_DATOS_PEDIDO_SUCCESS:
            draftState.datos.datosPedido = action.payload;
        break;

        case FETCH_ITEMS_SUCCESS:
            let total = 0;

            //Reseteo Valores
            draftState.total.subTotal = 0;
            draftState.total.importeTotal = 0;
            draftState.total.valorCuota = 0;
            draftState.total.descuentos = 0;
            draftState.total.redondeo = 0;
            draftState.total.impuestos = 0;
            draftState.items = action.payload;
            

            draftState.items.map((current, index) => {
                draftState.datos.datosPedido.Cuotas = current.Cuotas;
               // draftState.datos.datosPedido.currentLista = current.MedioTarjeta;
                let descuento = current.Descuento *  ( current.Precio_Unitario * current.Cantidad) / 100 ;
                draftState.total.cuotas = parseFloat(current.Cuotas)
                draftState.total.redondeo += parseFloat(current.Redondeo)
                draftState.total.impuestos += parseFloat(current.Impuestos)
                draftState.total.subTotal += parseFloat( current.Precio_Unitario * current.Cantidad  )
                draftState.total.importeTotal += parseFloat(current.Precio_Unitario * current.Cantidad - descuento + current.Redondeo)
                draftState.total.valorCuota += parseFloat(current.Cuota)
                draftState.total.descuentos += parseFloat(descuento)
            },0)
            draftState.loading.isLoading = false;
            break;

        case FETCH: 
            draftState.loading.isLoading = true;
            draftState.errors = false;
            break;

        case FETCH_ITEMS_FAILURE: 
            draftState.loading.isLoading = false;
            draftState.isFetching = false;
            draftState.errors = true;
            break;            

        case OPENDRAWER: 
            draftState.isCart = true;
            draftState.isOpen = true;
            break; 

        case CLOSEDRAWER: 
            draftState.isOpen = false;
            break;             

        default:

    }
})};

export default reducer;
