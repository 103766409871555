export const fetchAll = () => ({ skus: state }) => state.skus
export const isFetching = ({ skus: state }) => state.skusFetching;

export const getSkuDetails = skuId => ({ skus: state }) => {

    const index = state.skus.findIndex(sku => (
        skuId === sku.PLU
    ));

    return skuId && state.skus.length > 0 ? state.skus[index] : [];

}

export const fetchPriceListsBySku = (skuId, currentLista, disableFilterLista ) => ({ skus: state }) => {

    let getFilteredPriceLIst = (priceList, currentLista) => {
        if (currentLista && disableFilterLista == false){
            return priceList.filter((list) => list.nombreGrupo == currentLista)
        }else{
            return priceList;
        }
        
    }

    const index = state.skus.findIndex(sku => (
        skuId === sku.PLU
    ));

    return skuId && state.skus.length > 0 ? getFilteredPriceLIst(state.skus[index].pricelists, currentLista)  : [];

}



export const fetchPricesBySku = (skuId, listId, currentCuotas, disableFilterCuotas) => ({ skus: state }) => {

    let getPricesFiltered = (prices, currentCuotas) => {
        
        if (currentCuotas && disableFilterCuotas == false) {
            return prices.filter((price) => price.cuotasMin == currentCuotas)
        } else {
            return prices;
        }
    }
    
    const index = state.skus.findIndex(sku => (
        skuId === sku.PLU
    ));

    const indexL = state.skus[index].pricelists.findIndex(list => (
        listId === list.nombreGrupo
    ));


    return listId && state.skus[index].pricelists.length > 0 && state.skus[index].pricelists[indexL].installments ? getPricesFiltered(state.skus[index].pricelists[indexL].installments, currentCuotas)  : [];

}

export const getSkuInstallments = skuId => ({ skus: state }) => {

    const index = state.skus.findIndex(sku => (
        skuId === sku.PLU
    ));

    return skuId && state.skus.length > 0 ? state.skus[index] : [];

}

export const isLoading = () => ({ skus : state}) => state.loading.isLoading;
